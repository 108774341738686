import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  DateCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
  getEnumFilterOptions,
} from './column-utils';
import { getGlobalColumns } from './globalColumns';

export const getLocalUsersColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const localUsersColumnHelper = createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(schema, localUsersColumnHelper);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    localUsersColumnHelper.accessor((row) => row.username, {
      id: 'username',
      meta: {
        displayName: 'Username',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.uid, {
      id: 'uid',
      meta: {
        displayName: 'User ID',
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.type, {
      id: 'type',
      meta: {
        displayName: 'Type',
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          schema.schema?.definitions.LocalUsersTypes.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.filevault_user, {
      id: 'filevault_user',
      cell: YesNoCell,
      meta: {
        displayName: 'FileVault User',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.home_directory, {
      id: 'home_directory',
      cell: getCodeSnippetCell,
      meta: {
        displayName: 'Home Directory',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.user_created_at, {
      id: 'user_created_at',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: 'Created At',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.failed_logins, {
      id: 'failed_logins',
      meta: {
        defaultHidden: true,
        displayName: 'Failed Logins',
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.failed_login_time, {
      id: 'failed_login_time',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: 'Failed Login Time',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.password_last_set, {
      id: 'password_last_set',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: 'Password Last Set',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.generated_uid, {
      id: 'generated_uid',
      meta: {
        defaultHidden: true,
        displayName: 'Generated UID',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.directory, {
      id: 'directory',
      meta: {
        defaultHidden: true,
        displayName: 'Directory Node',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.shell, {
      id: 'shell',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'Shell',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.hidden_user, {
      id: 'hidden_user',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Hidden User',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.secure_token, {
      id: 'secure_token',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Secure Token',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.volume_owner, {
      id: 'volume_owner',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Volume Owner',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.full_name, {
      id: 'full_name',
      meta: {
        defaultHidden: true,
        displayName: 'Full Name',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.logged_in, {
      id: 'logged_in',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Logged In',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.logged_in_time, {
      id: 'logged_in_time',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: 'Logged In Timestamp',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.mobile_account, {
      id: 'mobile_account',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Mobile Account',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.home_folder_secure, {
      id: 'home_folder_secure',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Home Folder Secure',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
