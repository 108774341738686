import { updateSetting as update } from '@kandji-inc/bumblebee';
import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import LibraryContext from 'features/library-items/routes/library.context';
import { i18n } from 'i18n';
/* istanbul ignore file */
import React, { useCallback, useContext } from 'react';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import type { IpaFile } from './in-house-apps.types';
import initialState from './initial-state';
import { InstallDetails } from './sections/install-details';
import { transformFromApi, transformToApi } from './service/transformers';
import useInHouseAppsService, {
  inHouseAppsService,
} from './service/use-in-house-apps.service';

export default function InHouseApp() {
  const { itemConfig } = useContext(LibraryContext);
  const blueprintConflicts = useBlueprintConflicts();
  const [_, triggerValidation] = useUniqValue();

  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useInHouseAppsService,
  });

  const { model, setModel, pageState, handlers } = pageProps;
  const updateInstallDetails = useCallback(
    update('installDetails', setModel),
    [],
  );

  function updateIpaFile(updateBody: Partial<IpaFile>) {
    updateInstallDetails('ipaFile', (previous: IpaFile) => ({
      ...previous,
      ...updateBody,
    }));
  }

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <LibraryItemPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="ipa-apps-v2"
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} ${i18n.t('Profile')}`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={inHouseAppsService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules={false}
      supportsDuplication
      supportsInstallOn
    >
      <InstallDetails
        isDisabled={pageState.isDisabled}
        model={model}
        updateIpaFile={updateIpaFile}
        onValidate={handlers.onValidate}
        isSubmitted={pageState.isSubmitted}
      />
    </LibraryItemPage>
  );
}
