import featureFlags from 'src/config/feature-flags';
/* istanbul ignore file */
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import uuidv4 from 'uuid/v4';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { profileApps } from '../../../library/library-item-configurations/items';
import Auth from '../../kandji-login/settings/auth';
import initialState from '../initial-state';
import { extensionTypes } from '../sections/extensions/constants';
import SingleSignOnExtensionService from './single-sign-on-extension.service';

const getFromSetting =
  (setting) =>
  (key, def = null, transform = null) => {
    if (!(key in setting)) {
      return def;
    }
    if (transform) {
      return transform(setting[key]);
    }
    return setting[key];
  };

const isNotNull = (v) => v !== null;

const transformFromApi = async (apiData) => {
  const LDFF_wwdc23PlatformSso = featureFlags.getFlag(
    'LIT-WWDC23-Platform-SSO',
  );

  const LD_wwdc24_SSO_changes = featureFlags.getFlag('DC-WWDC24-SSO-changes');

  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const getSetting = getFromSetting(apiData.data);

  const data = {
    id: apiData.id,
    name: apiData.name,
    isActive: apiData.active,
    devices: getSelectedDevicesFromRunsOn(apiData),
    rules: apiData.rules,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,

    details: {
      // Extension details
      type: getSetting('type'),
      extension_identifier: getSetting('extension_identifier'),
      team_identifier: getSetting('team_identifier'),
      realm: getSetting('realm'),
      hosts: getSetting('hosts', initialState.details.hosts, (hosts) =>
        hosts
          ? hosts.map((value) => ({ _id: uuidv4(), value }))
          : initialState.details.hosts,
      ),
      urls: getSetting('urls', initialState.details.urls, (urls) =>
        urls
          ? urls.map((value) => ({ _id: uuidv4(), value }))
          : initialState.details.urls,
      ),

      ...(LD_wwdc24_SSO_changes &&
      (getSetting('type') === extensionTypes.credential ||
        getSetting('type') === extensionTypes.redirect)
        ? {
            DeniedBundleIdentifiers: getSetting(
              'DeniedBundleIdentifiers',
              initialState.details.DeniedBundleIdentifiers,
              (v) =>
                v
                  ? v.map((value) => ({ _id: uuidv4(), value }))
                  : initialState.details.DeniedBundleIdentifiers,
            ),
          }
        : {}),

      // Kerberos password options
      disallow_automatic_login: getSetting('disallow_automatic_login'),
      disallow_password_change: getSetting('disallow_password_change'),
      require_complex_passwords: getSetting('require_complex_passwords'),
      password_history: getSetting('password_history'), // number
      isPasswordHistory: getSetting('password_history', false, isNotNull), // local boolean
      password_length: getSetting('password_length'), // number
      isPasswordLength: getSetting('password_length', false, isNotNull), // local boolean
      password_requirements_text: getSetting('password_requirements_text'),
      isPasswordRequirementsText: getSetting(
        'password_requirements_text',
        false,
        isNotNull,
      ), // local boolean
      sync_local_password: getSetting('sync_local_password'),
      password_change_url: getSetting('password_change_url'),
      isChangePasswordURL: getSetting('password_change_url', false, isNotNull), // local boolean
      max_password_age: getSetting('max_password_age'), // number
      isMaximumPasswordAge: getSetting('max_password_age', false, isNotNull), // local boolean
      password_expiration_notification: getSetting(
        'password_expiration_notification',
      ), // number
      isPasswordExpirationNotification: getSetting(
        'password_expiration_notification',
        false,
        isNotNull,
      ), // local boolean
      min_password_age: getSetting('min_password_age'), // number
      isMinimumPasswordAge: getSetting('min_password_age', false, isNotNull), // local boolean

      // Kerberos advanced
      cache_name: getSetting('cache_name'),
      isCacheName: getSetting('cache_name', false, isNotNull), // local boolean
      bundle_id_access: getSetting(
        'bundle_id_access',
        initialState.details.bundle_id_access,
        (bundle_id_access) =>
          bundle_id_access
            ? bundle_id_access.map((value) => ({ _id: uuidv4(), value }))
            : initialState.details.bundle_id_access,
      ),
      isBundleIdAccess: getSetting('bundle_id_access', false, isNotNull), // local boolean
      autofill_username: getSetting('autofill_username'),
      isAutoFillUsername: getSetting('autofill_username', false, isNotNull), // local boolean
      default_realm: getSetting('default_realm'),
      require_user_presence: getSetting('require_user_presence'),
      site_code: getSetting('site_code'),
      isSiteCode: getSetting('site_code', false, isNotNull), // local boolean
      disable_site_auto_discovery: getSetting('disable_site_auto_discovery'),
      custom_username_label: getSetting('custom_username_label'),
      isCustomUsernameLabel: getSetting(
        'custom_username_label',
        false,
        isNotNull,
      ), // local boolean
      delay_user_setup: getSetting('delay_user_setup'),
      help_text: getSetting('help_text'),
      isHelpText: getSetting('help_text', false, isNotNull), // local boolean
      include_managed_apps_in_bundle_id_ACL: getSetting(
        'include_managed_apps_in_bundle_id_ACL',
      ),
      monitor_credentials_cache: getSetting('monitor_credentials_cache'),
      replication_time: getSetting('replication_time'), // number
      isReplicationTime: getSetting('replication_time', false, isNotNull), // local boolean
      allowSmartCard: getSetting('allowSmartCard', false),
      allowPassword: getSetting('allowPassword', false),
      startInSmartCardMode: getSetting('startInSmartCardMode', false),
      identityIssuerAutoSelectFilter: getSetting(
        'identityIssuerAutoSelectFilter',
        null,
      ),

      extension_details: getSetting('extension_details', false, (v) => v || ''), // string
      isExtensionDetails: getSetting('extension_details', false, isNotNull), // local boolean
    },

    ...(LDFF_wwdc23PlatformSso &&
    (getSetting('type') === extensionTypes.credential ||
      getSetting('type') === extensionTypes.redirect)
      ? {
          platform: {
            PlatformSSOActive: getSetting('PlatformSSOActive', false),
            AuthenticationMethod: getSetting(
              'AuthenticationMethod',
              SingleSignOnExtensionService.AuthorizationMethod.Password,
            ),
            UserAuthorizationMode: getSetting(
              'UserAuthorizationMode',
              SingleSignOnExtensionService.AuthorizationMode.Standard,
            ),
            NewUserAuthorizationMode: getSetting(
              'NewUserAuthorizationMode',
              SingleSignOnExtensionService.AuthorizationMode.Standard,
            ),
            UseSharedDeviceKeys: getSetting('UseSharedDeviceKeys', false),
            EnableAuthorization: getSetting('EnableAuthorization', false),
            EnableCreateUserAtLogin: getSetting(
              'EnableCreateUserAtLogin',
              false,
            ),
            AccountDisplayName: getSetting('AccountDisplayName', ''),
            LoginFrequency: getSetting('LoginFrequency', 64800),
            AccountName: getSetting('AccountName', ''),
            FullName: getSetting('FullName', ''),
            AdministratorGroups: getSetting(
              'AdministratorGroups',
              initialState.platform.AdministratorGroups,
            ),
            AdditionalGroups: getSetting(
              'AdditionalGroups',
              initialState.platform.AdditionalGroups,
            ),
            AuthorizationGroups: getSetting(
              'AuthorizationGroups',
              initialState.platform.AuthorizationGroups,
            ),
            ...(LD_wwdc24_SSO_changes && {
              // FileVault Policy:
              SetFileVaultPolicy: getSetting('FileVaultPolicy', null, (v) => {
                return !!(v && v.length > 0);
              }),
              FileVaultPolicy: getSetting('FileVaultPolicy', null, (v) => {
                if (
                  v &&
                  v.includes(
                    SingleSignOnExtensionService.PolicyAuthenticationOptions
                      .Attempt,
                  )
                ) {
                  return SingleSignOnExtensionService
                    .PolicyAuthenticationOptions.Attempt;
                }

                if (
                  v &&
                  v.includes(
                    SingleSignOnExtensionService.PolicyAuthenticationOptions
                      .Required,
                  )
                ) {
                  return SingleSignOnExtensionService
                    .PolicyAuthenticationOptions.Required;
                }
              }),
              FileVaultPolicyAllowOfflineGracePeriod: getSetting(
                'FileVaultPolicy',
                null,
                (v) => {
                  return !!(v && v.includes('AllowOfflineGracePeriod'));
                },
              ),
              FileVaultPolicyAllowAuthenticationGracePeriod: getSetting(
                'FileVaultPolicy',
                null,
                (v) => {
                  return !!(v && v.includes('AllowAuthenticationGracePeriod'));
                },
              ),

              // Login Policy:
              SetLoginPolicy: getSetting('LoginPolicy', null, (v) => {
                return !!(v && v.length > 0);
              }),
              LoginPolicy: getSetting('LoginPolicy', null, (v) => {
                if (
                  v &&
                  v.includes(
                    SingleSignOnExtensionService.PolicyAuthenticationOptions
                      .Attempt,
                  )
                ) {
                  return SingleSignOnExtensionService
                    .PolicyAuthenticationOptions.Attempt;
                }

                if (
                  v &&
                  v.includes(
                    SingleSignOnExtensionService.PolicyAuthenticationOptions
                      .Required,
                  )
                ) {
                  return SingleSignOnExtensionService
                    .PolicyAuthenticationOptions.Required;
                }
              }),
              LoginPolicyAllowOfflineGracePeriod: getSetting(
                'LoginPolicy',
                null,
                (v) => {
                  return !!(v && v.includes('AllowOfflineGracePeriod'));
                },
              ),
              LoginPolicyAllowAuthenticationGracePeriod: getSetting(
                'LoginPolicy',
                null,
                (v) => {
                  return !!(v && v.includes('AllowAuthenticationGracePeriod'));
                },
              ),

              // Unlock Policy:
              SetUnlockPolicy: getSetting('UnlockPolicy', null, (v) => {
                return !!(v && v.length > 0);
              }),
              UnlockPolicy: getSetting('UnlockPolicy', null, (v) => {
                if (
                  v &&
                  v.includes(
                    SingleSignOnExtensionService.PolicyAuthenticationOptions
                      .Attempt,
                  )
                ) {
                  return SingleSignOnExtensionService
                    .PolicyAuthenticationOptions.Attempt;
                }

                if (
                  v &&
                  v.includes(
                    SingleSignOnExtensionService.PolicyAuthenticationOptions
                      .Required,
                  )
                ) {
                  return SingleSignOnExtensionService
                    .PolicyAuthenticationOptions.Required;
                }
              }),
              UnlockPolicyAllowOfflineGracePeriod: getSetting(
                'UnlockPolicy',
                null,
                (v) => {
                  return !!(v && v.includes('AllowOfflineGracePeriod'));
                },
              ),
              UnlockPolicyAllowAuthenticationGracePeriod: getSetting(
                'UnlockPolicy',
                null,
                (v) => {
                  return !!(v && v.includes('AllowAuthenticationGracePeriod'));
                },
              ),
              UnlockPolicyAllowTouchIDOrWatchForUnlock: getSetting(
                'UnlockPolicy',
                null,
                (v) => {
                  return !!(v && v.includes('AllowTouchIDOrWatchForUnlock'));
                },
              ),

              // Grace periods:
              OfflineGracePeriod: getSetting('OfflineGracePeriod', 120),
              AuthenticationGracePeriod: getSetting(
                'AuthenticationGracePeriod',
                120,
              ),
              NonPlatformSSOAccounts: getSetting(
                'NonPlatformSSOAccounts',
                initialState.platform.NonPlatformSSOAccounts,
                (v) =>
                  v
                    ? v.map((value) => ({ _id: uuidv4(), value }))
                    : initialState.platform.NonPlatformSSOAccounts,
              ),
            }),
          },
        }
      : {}),
  };

  return {
    ...apiData,
    data,
  };
};

function transformToApi(model) {
  const LDFF_wwdc23PlatformSso = featureFlags.getFlag(
    'LIT-WWDC23-Platform-SSO',
  );
  const LD_wwdc24_SSO_changes = featureFlags.getFlag('DC-WWDC24-SSO-changes');

  const getSetting = getFromSetting(model.details);
  const getPlatformSetting = getFromSetting(model.platform);

  const canSetOfflineGracePeriod = [
    getPlatformSetting('SetFileVaultPolicy') &&
      getPlatformSetting('FileVaultPolicy') ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      getPlatformSetting('FileVaultPolicyAllowOfflineGracePeriod'),
    getPlatformSetting('SetLoginPolicy') &&
      getPlatformSetting('LoginPolicy') ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      getPlatformSetting('LoginPolicyAllowOfflineGracePeriod'),
    getPlatformSetting('SetUnlockPolicy') &&
      getPlatformSetting('UnlockPolicy') ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      getPlatformSetting('UnlockPolicyAllowOfflineGracePeriod'),
  ].some((val) => !!val);

  const canSetAuthenticationGracePeriod = [
    getPlatformSetting('SetFileVaultPolicy') &&
      getPlatformSetting('FileVaultPolicy') ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      getPlatformSetting('FileVaultPolicyAllowAuthenticationGracePeriod'),
    getPlatformSetting('SetLoginPolicy') &&
      getPlatformSetting('LoginPolicy') ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      getPlatformSetting('LoginPolicyAllowAuthenticationGracePeriod'),
    getPlatformSetting('SetUnlockPolicy') &&
      getPlatformSetting('UnlockPolicy') ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      getPlatformSetting('UnlockPolicyAllowAuthenticationGracePeriod'),
  ].some((val) => !!val);

  const isAuthPasswordMode =
    getPlatformSetting('AuthenticationMethod') ===
    SingleSignOnExtensionService.AuthorizationMethod.Password;

  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints?.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    data: {
      type: getSetting('type'),
      extension_identifier: getSetting('extension_identifier'),
      team_identifier: getSetting('team_identifier'),
      realm: getSetting('realm'),
      hosts: getSetting('hosts', undefined, (v) =>
        getSetting('type', undefined, (t) =>
          t !== extensionTypes.redirect ? v.map(({ value }) => value) : null,
        ),
      ),
      urls: getSetting('urls', undefined, (v) =>
        getSetting('type', undefined, (t) =>
          t === extensionTypes.redirect ? v.map(({ value }) => value) : null,
        ),
      ),

      disallow_automatic_login: getSetting('disallow_automatic_login'),
      disallow_password_change: getSetting('disallow_password_change'),
      require_complex_passwords: getSetting('require_complex_passwords'),
      password_history: getSetting('password_history', undefined, (h) =>
        getSetting('isPasswordHistory', undefined, (p) => (p ? h : null)),
      ),
      password_length: getSetting('password_length', undefined, (h) =>
        getSetting('isPasswordLength', undefined, (p) => (p ? h : null)),
      ),
      password_requirements_text: getSetting(
        'password_requirements_text',
        undefined,
        (h) =>
          getSetting('isPasswordRequirementsText', undefined, (p) =>
            !getSetting('isPasswordLength') &&
            !getSetting('require_complex_passwords') &&
            p
              ? h
              : null,
          ),
      ),
      sync_local_password: getSetting('sync_local_password'),
      password_change_url: getSetting('password_change_url', undefined, (h) =>
        getSetting('isChangePasswordURL', undefined, (p) => (p ? h : null)),
      ),
      max_password_age: getSetting('max_password_age', undefined, (h) =>
        getSetting('isMaximumPasswordAge', undefined, (p) => (p ? h : null)),
      ),
      password_expiration_notification: getSetting(
        'password_expiration_notification',
        undefined,
        (h) =>
          getSetting('isPasswordExpirationNotification', undefined, (p) =>
            p ? h : null,
          ),
      ),
      min_password_age: getSetting('min_password_age', undefined, (h) =>
        getSetting('isMinimumPasswordAge', undefined, (p) => (p ? h : null)),
      ),

      cache_name: getSetting('cache_name', undefined, (h) =>
        getSetting('isCacheName', undefined, (p) => (p ? h : null)),
      ),
      bundle_id_access: getSetting('bundle_id_access', undefined, (h) =>
        getSetting('isBundleIdAccess', undefined, (p) =>
          p ? h.map(({ value }) => value) : null,
        ),
      ),
      autofill_username: getSetting('autofill_username', undefined, (h) =>
        getSetting('isAutoFillUsername', undefined, (p) => (p ? h : null)),
      ),
      default_realm: getSetting('default_realm'),
      require_user_presence: getSetting('require_user_presence'),
      site_code: getSetting('site_code', undefined, (h) =>
        getSetting('isSiteCode', undefined, (p) => (p ? h : null)),
      ),
      disable_site_auto_discovery: getSetting('disable_site_auto_discovery'),
      custom_username_label: getSetting(
        'custom_username_label',
        undefined,
        (h) =>
          getSetting('isCustomUsernameLabel', undefined, (p) => (p ? h : null)),
      ),
      delay_user_setup: getSetting('delay_user_setup'),
      help_text: getSetting('help_text', undefined, (h) =>
        getSetting('isHelpText', undefined, (p) => (p ? h : null)),
      ),
      include_managed_apps_in_bundle_id_ACL: getSetting(
        'include_managed_apps_in_bundle_id_ACL',
      ),
      monitor_credentials_cache: getSetting('monitor_credentials_cache'),
      replication_time: getSetting('replication_time', undefined, (h) =>
        getSetting('isReplicationTime', undefined, (p) => (p ? h : null)),
      ),
      ...(LD_wwdc24_SSO_changes && {
        allowSmartCard: getSetting('allowSmartCard', null, (v) =>
          getSetting('type') === extensionTypes.kerberos ? v : null,
        ),
        allowPassword: getSetting('allowPassword', null, (v) =>
          getSetting('type') === extensionTypes.kerberos ? v : null,
        ),
        startInSmartCardMode: getSetting('startInSmartCardMode', null, (v) =>
          getSetting('type') === extensionTypes.kerberos ? v : null,
        ),
        identityIssuerAutoSelectFilter: getSetting(
          'identityIssuerAutoSelectFilter',
          null,
          (v) => (getSetting('type') === extensionTypes.kerberos ? v : null),
        ),
      }),
      updated_at: +Date.now(),

      extension_details: getSetting('extension_details', undefined, (h) => {
        const type = getSetting('type');
        if (type !== extensionTypes.kerberos) {
          return getSetting('isExtensionDetails', undefined, (p) =>
            p ? h : null,
          );
        }

        return null;
      }),

      ...(LD_wwdc24_SSO_changes && {
        DeniedBundleIdentifiers: getSetting(
          'DeniedBundleIdentifiers',
          null,
          (v) => (!v || v.length < 1 ? null : v.map(({ value }) => value)),
        ),
      }),

      // platform
      ...(LDFF_wwdc23PlatformSso &&
      model.platform.PlatformSSOActive &&
      (getSetting('type') === extensionTypes.credential ||
        getSetting('type') === extensionTypes.redirect)
        ? {
            PlatformSSOActive: getPlatformSetting('PlatformSSOActive'),
            AuthenticationMethod: getPlatformSetting('AuthenticationMethod'),
            UserAuthorizationMode: getPlatformSetting('UserAuthorizationMode'),
            NewUserAuthorizationMode: getPlatformSetting(
              'NewUserAuthorizationMode',
            ),
            UseSharedDeviceKeys: getPlatformSetting('UseSharedDeviceKeys'),
            ...(getPlatformSetting('UseSharedDeviceKeys')
              ? {
                  EnableAuthorization: getPlatformSetting(
                    'EnableAuthorization',
                  ),
                }
              : {}),
            ...(getPlatformSetting('UseSharedDeviceKeys')
              ? {
                  EnableCreateUserAtLogin: getPlatformSetting(
                    'EnableCreateUserAtLogin',
                  ),
                }
              : {}),
            AccountDisplayName: getPlatformSetting('AccountDisplayName'),
            LoginFrequency: getPlatformSetting(
              'LoginFrequency',
              undefined,
              (v) => parseInt(v, 10),
            ),
            AccountName: getPlatformSetting('AccountName'),
            FullName: getPlatformSetting('FullName'),
            ...(getPlatformSetting('AdministratorGroups').length
              ? {
                  AdministratorGroups: getPlatformSetting(
                    'AdministratorGroups',
                  ),
                }
              : {}),
            ...(getPlatformSetting('AdditionalGroups').length
              ? { AdditionalGroups: getPlatformSetting('AdditionalGroups') }
              : {}),
            ...(getPlatformSetting('AuthorizationGroups').length
              ? {
                  AuthorizationGroups: getPlatformSetting(
                    'AuthorizationGroups',
                  ),
                }
              : {}),
            ...(LD_wwdc24_SSO_changes && {
              ...(isAuthPasswordMode && {
                FileVaultPolicy: getPlatformSetting('SetFileVaultPolicy')
                  ? transformPolicyFieldsToArray(
                      getPlatformSetting('FileVaultPolicy'),
                      getPlatformSetting(
                        'FileVaultPolicyAllowOfflineGracePeriod',
                      ),
                      getPlatformSetting(
                        'FileVaultPolicyAllowAuthenticationGracePeriod',
                      ),
                    )
                  : null,
              }),
              ...(isAuthPasswordMode && {
                LoginPolicy: getPlatformSetting('SetLoginPolicy')
                  ? transformPolicyFieldsToArray(
                      getPlatformSetting('LoginPolicy'),
                      getPlatformSetting('LoginPolicyAllowOfflineGracePeriod'),
                      getPlatformSetting(
                        'LoginPolicyAllowAuthenticationGracePeriod',
                      ),
                    )
                  : null,
              }),
              ...(isAuthPasswordMode && {
                UnlockPolicy: getPlatformSetting('SetUnlockPolicy')
                  ? transformPolicyFieldsToArray(
                      getPlatformSetting('UnlockPolicy'),
                      getPlatformSetting('UnlockPolicyAllowOfflineGracePeriod'),
                      getPlatformSetting(
                        'UnlockPolicyAllowAuthenticationGracePeriod',
                      ),
                      getPlatformSetting(
                        'UnlockPolicyAllowTouchIDOrWatchForUnlock',
                      ),
                    )
                  : null,
              }),
              // Grace periods:
              ...(isAuthPasswordMode && {
                OfflineGracePeriod: canSetOfflineGracePeriod
                  ? +getPlatformSetting('OfflineGracePeriod') // convert to number
                  : null,
              }),
              ...(isAuthPasswordMode && {
                AuthenticationGracePeriod: canSetAuthenticationGracePeriod
                  ? +getPlatformSetting('AuthenticationGracePeriod')
                  : null,
              }),
              NonPlatformSSOAccounts: getPlatformSetting(
                'NonPlatformSSOAccounts',
                null,
                (v) => {
                  return !v || v.length < 1
                    ? null
                    : v.map(({ value }) => value);
                },
              ),
            }),
          }
        : {}),
    },
  };

  if (!model.id) {
    const itemConfig = profileApps['Single Sign-On Extension'];
    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  return toSend;
}

export function transformPolicyFieldsToArray(
  authOption,
  allowOfflineGracePeriod,
  allowAuthenticationGracePeriod,
  allowTouchIDOrWatchForUnlock?,
) {
  const policyArray = [];

  if (
    authOption ===
    SingleSignOnExtensionService.PolicyAuthenticationOptions.Attempt
  ) {
    policyArray.push(
      SingleSignOnExtensionService.PolicyAuthenticationOptions.Attempt,
    );
  }

  if (
    authOption ===
    SingleSignOnExtensionService.PolicyAuthenticationOptions.Required
  ) {
    policyArray.push(
      SingleSignOnExtensionService.PolicyAuthenticationOptions.Required,
    );
  }

  if (
    authOption ===
    SingleSignOnExtensionService.PolicyAuthenticationOptions.Required
  ) {
    if (allowOfflineGracePeriod) {
      policyArray.push('AllowOfflineGracePeriod');
    }

    if (allowAuthenticationGracePeriod) {
      policyArray.push('AllowAuthenticationGracePeriod');
    }

    if (allowTouchIDOrWatchForUnlock) {
      policyArray.push('AllowTouchIDOrWatchForUnlock');
    }
  }

  return policyArray;
}

export { transformToApi, transformFromApi };
