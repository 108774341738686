import isoToDateString from '../../common/utils/isoToDateString';

const getTimezoneAbbreviation = (timezone: string) => {
  const date = new Date();
  const options = { timeZone: timezone, timeZoneName: 'short' };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(date);
  const timeZonePart = parts.find((part) => part.type === 'timeZoneName');
  return timeZonePart ? timeZonePart.value : '';
};

const getDate = (date: string, timezone: string, withTime: boolean = false) => {
  if (!date) {
    return '';
  }

  const newDate = isoToDateString(date, timezone, withTime, {
    month: '2-digit',
    day: '2-digit',
  });

  if (withTime) {
    const timezoneAbbreviation = getTimezoneAbbreviation(timezone);
    return `${newDate.replace(', ', ' at ')} (${timezoneAbbreviation})`;
  }

  return newDate;
};

export default getDate;
