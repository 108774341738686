import Axios from 'axios';
import { enterpriseExternalApi } from 'src/app/api/base';

const IPA_UPLOAD_URL = 'library/ipa-apps/upload';

export enum IpaUploadStatus {
  UPLOADING = 'UPLOADING',
  VALIDATED = 'VALIDATED',
}

export interface IpaApiStatusData {
  status: IpaUploadStatus;
  sha256: string;
  metadata: {
    app_identifier: string;
    icon: string | null | undefined;
    ipad_icon: string | null | undefined;
    app_version: string;
    app_name: string;
    file_size: number;
  };
}

export async function getIpaUploadStatus(
  pendingUploadId: string,
  retries = 120,
  delay = 5000,
): Promise<IpaApiStatusData> {
  const uploadStatusApi = enterpriseExternalApi(
    `${IPA_UPLOAD_URL}/${pendingUploadId}/status`,
  );

  return new Promise((resolve, reject) => {
    uploadStatusApi
      .get({})
      .then((response) => {
        const { data } = response;

        if (retries === 0) reject(new Error('Could not calculate SHA256'));

        switch (data.status) {
          case IpaUploadStatus.VALIDATED:
            resolve(data);
            break;
          case IpaUploadStatus.UPLOADING:
            setTimeout(() => {
              getIpaUploadStatus(pendingUploadId, retries - 1, delay).then(
                resolve,
                reject,
              );
            }, delay);
            break;
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export interface IpaS3Data {
  id: string;
  filename: string;
  sha256: null | string;
  expires: string;
  post_url: string;
  post_data: {
    key: string;
    'x-amz-algorithm': string;
    'x-amz-credential': string;
    'x-amz-date': string;
    'x-amz-security-token': string;
    policy: string;
    'x-amz-signature': string;
  };
  file_key: string;
}

export async function getIPAS3Data(filename: string): Promise<IpaS3Data> {
  const uploadApi = enterpriseExternalApi(IPA_UPLOAD_URL);
  const { data } = await uploadApi.post({ filename });

  return data;
}

export const uploadIpaAppToS3 = (ipaS3Data: IpaS3Data, file, options) => {
  try {
    const { post_url: url, post_data: postData } = ipaS3Data;
    const formData = new FormData();
    Object.entries(postData).forEach((values) => formData.append(...values));
    formData.append('file', file);
    return Axios.post(url, formData, options);
  } catch (err) {
    console.log(err);
  }
};

export const uploadIpaApp = (file, onUploadProgress, ipaS3Data: IpaS3Data) => {
  const CancelSource = Axios.CancelToken.source();
  return {
    cancel: CancelSource.cancel,
    upload: uploadIpaAppToS3(ipaS3Data, file, {
      onUploadProgress: (p) =>
        onUploadProgress(Math.round((p.loaded / p.total) * 100)),
      cancelToken: CancelSource.token,
    }),
  };
};
