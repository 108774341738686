/* istanbul ignore file */
import { i18n } from 'src/i18n';

export const formatDate = (
  props: {
    date: string;
    showTimestamp?: boolean; // ex. MM/DD/YYYY, 12:00:00 AM
    showUserFriendlyTimestamp?: boolean; // ex. September 1, 2021 at 12:00:00 AM PST
  } = { date: '', showTimestamp: false, showUserFriendlyTimestamp: false },
): string => {
  const { date: dateString, showTimestamp, showUserFriendlyTimestamp } = props;

  // Ensure the date is interpreted as UTC by appending 'Z'
  const date = new Date(`${dateString}Z`);

  // Return date with full time if showTimestamp is true and isOnTimeline is false
  // ex. MM/DD/YYYY, 12:00:00 AM
  if (showTimestamp && !showUserFriendlyTimestamp) {
    return i18n.format.datetime(date);
  }

  // Return date with time and timezone if isOnTimeline is true
  // ex. September 1, 2021 at 12:00:00 AM PST
  if (showUserFriendlyTimestamp) {
    return i18n.format.datetimeLong(date);
  }

  // Default return, MM/DD/YYYY
  return i18n.format.date(date);
};

const getTextWidth = (text: string, element: HTMLElement = document.body) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Get the computed style of the element and set it on the canvas context
  const style = window.getComputedStyle(element);
  context.font = `${style.fontSize} ${style.fontFamily}`;

  return context.measureText(text).width;
};

export const getTimeBetween = (firstDate: Date, secondDate: Date) => {
  const diffTime = Math.abs(secondDate.getTime() - firstDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffMonths = Math.ceil(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  let displayText = '';

  if (diffDays < 31) {
    displayText = i18n.common.durationDays(diffDays);
  } else if (diffMonths < 12) {
    displayText = i18n.common.durationMonths(diffMonths);
  } else {
    displayText = i18n.common.durationYears(diffYears);
  }

  return displayText;
};

export const displayAndTruncateList = (
  strings: string[],
  maxWidth: number = 530,
  separator = ', ',
  element = document.body,
) => {
  const displayed = [];
  let hidden = [];
  let totalWidth = 0;

  for (let i = 0; i < strings.length; i++) {
    const string = strings[i];
    const stringWidth = getTextWidth(string, element);
    const separatorWidth = i > 0 ? getTextWidth(separator, element) : 0;

    if (totalWidth + stringWidth + separatorWidth > maxWidth) {
      hidden = strings.slice(i); // All remaining strings are hidden
      break;
    }

    displayed.push(string);
    totalWidth += stringWidth + separatorWidth;
  }

  return { displayed, hidden };
};
