import { Icon, setClass, useAfterMount } from '@kandji-inc/bumblebee';
import { Hint } from '@kandji-inc/nectar-ui';
import _cond from 'lodash/cond';
import styled from 'styled-components';
/* istanbul ignore file */
import './uploader.css';
import { i18n } from 'i18n';
import React, { useCallback, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { toImage, toMb } from '../../../common/utils/uploader-utils';
import WebClipDefault from '../../../library/library-item-configurations/assets/WebClipDefault.png';

const StyledParagraph = styled.p`
  font-size: 12px;
  color: #505E71;
  text-align: center;
`;

const StyledHint = styled.div`
  width: 400px;
  margin-top: 20px;
`;

const WebClipIconUploader = (props) => {
  const {
    style,
    className,
    onImage,
    maxSizeAllowed = 4e6,
    disabled = false,
    existingImgUrl,
    defaultIcon = WebClipDefault,
    onSelect = () => {},
    smallPreview = true,
    accept = 'image/*',
    isDarkMode = false,
    previewClass = '',
    validators,
    runValidatorsOn = [],
  } = props;
  const isAfterMount = useAfterMount();
  const [image, setImage] = useState({ dataUrl: '' });
  const [imgSrc, setImgSrc] = useState();
  const [error, setError] = useState(null);
  const [isHoverPreview, setIsHoverPreview] = useState(false);
  const [canDeleteIcon, setCanDeleteIcon] = useState(false);
  const [revert, setRevert] = useState(false);
  const defaultIconName = 'WebClipDefault.png';

  const onDrop = useCallback(
    (files) => {
      /* gets called when dropped, arg passed in is files */
      const file = files[0];
      toImage(file)
        .then((img: ImageDetails) => onSelect(img))
        .catch(console.log);
    },
    [onSelect],
  );

  const onDropAccepted = useCallback((acceptedFiles) => {
    setError(null);
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0];
      if (validators) {
        toImage(file)
          .then((theImage: ImageDetails) => {
            const errors = validators
              .map((vdator) => vdator(file, theImage))
              .filter(Boolean);
            if (errors.length) {
              setError({
                type: 'custom',
                message: errors[0],
              });
            } else {
              setCanDeleteIcon(true);
              setImage(theImage);
            }
          })
          .catch(console.log);
      }
    }
  }, []);

  const setDefaultDataURL = useCallback(() => {
    if (revert || !existingImgUrl) {
      fetch(defaultIcon)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], defaultIconName, {
            type: 'image/png',
          });
          return toImage(file);
        })
        .then((theImage: ImageDetails) => {
          setRevert(true);
          onImage(theImage);
          setImage(theImage);
          setImgSrc(theImage.dataUrl);
        })
        .catch(console.log);
    }
  }, [defaultIcon]);

  useEffect(() => {
    if (props.canRemove) {
      setImgSrc(props.canRemove);
    }
    setDefaultDataURL();
  }, [setDefaultDataURL]);

  const onDropRejected = useCallback(() => {
    /* gets call when rejected, arg is 'fileRejections' */
  }, []);

  useEffect(() => {
    if (existingImgUrl) {
      setImgSrc(existingImgUrl);
      setCanDeleteIcon(true);
    }
  }, []);

  useEffect(() => {
    if (image.dataUrl !== '' && image?.file.name !== defaultIconName) {
      setImgSrc(image.dataUrl);
      setCanDeleteIcon(true);
      if (onImage) {
        onImage(image);
      }
    } else {
      setCanDeleteIcon(false);
    }
  }, [image]);

  useEffect(() => {
    if (validators && isAfterMount) {
      const errors = validators
        .map((vdator) => vdator(image?.file, image))
        .filter(Boolean);
      if (errors.length) {
        setError({
          type: 'custom',
          message: errors[0],
        });
      } else {
        setError(undefined);
      }
    }
  }, runValidatorsOn);

  const validator = useCallback((file) => {
    if (file.size > maxSizeAllowed) {
      setError({
        type: 'max-size',
        message: i18n.t(
          'Upload a file that has a max file size of {size} MB.',
          { size: toMb(maxSizeAllowed) },
        ),
      });
    }
  }, []);

  const handleImageReset = () => {
    setRevert(true);
    if (!disabled && canDeleteIcon) {
      fetch(defaultIcon)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], defaultIconName, {
            type: 'image/png',
          });
          return toImage(file);
        })
        .then((theImage: ImageDetails) => {
          setRevert(true);
          onImage(theImage);
          setImage(theImage);
          setImgSrc(theImage.dataUrl);
          setCanDeleteIcon(false);
        })
        .catch(console.log);
    }
  };

  return (
    <div
      style={style}
      className={setClass([
        'k-library-org-logo',
        className,
        disabled && 'k-library-org-logo--disabled',
      ])}
    >
      <div
        className={setClass([
          previewClass,
          'k-library-org-logo__preview',
          smallPreview && '--small-preview',
          error && '--has-error',
          !disabled &&
            canDeleteIcon &&
            isHoverPreview &&
            'k-library-org-logo__preview--delete',
          isDarkMode && '--dark-mode',
        ])}
        style={{
          backgroundImage: `url(${imgSrc})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          borderRadius: '8px',
        }}
        onClick={handleImageReset}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleImageReset();
          }
        }}
        onMouseEnter={() => setIsHoverPreview(true)}
        onMouseLeave={() => setIsHoverPreview(false)}
        tabIndex={0} // Ensure the div is focusable
      >
        {!disabled && canDeleteIcon && isHoverPreview ? (
          <div className="k-library-org-logo__preview__delete-overlay">
            <Icon name="trash-can" />
          </div>
        ) : null}
      </div>
      <div className="k-library-org-logo__uploader">
        <Dropzone
          disabled={disabled}
          validator={validator}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          accept={accept}
          maxSize={maxSizeAllowed}
          onDrop={onDrop}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              className={setClass([
                'k-library-org-webclip__droparea',
                isDragActive && 'k-library-org-webclip__droparea--active',
              ])}
              {...getRootProps()}
            >
              <input {...getInputProps()} data-testid="dropzone-input" />

              <Icon name="file-arrow-up" />
              <StyledParagraph>
                {i18n.t('Drop a ')}
                <strong>.png</strong>
                {i18n.t(' file here or')}{' '}
                <a
                  href=""
                  onClick={(e) => e.preventDefault()}
                  className="b-alink"
                >
                  {i18n.t('select file')}
                </a>
              </StyledParagraph>
            </div>
          )}
        </Dropzone>
      </div>
      {error && (
        <div className="k-library-org-logo__error">
          <Icon name="octagon-exclamation" />
          <p className="b-txt">{error.message}</p>
        </div>
      )}

      <StyledHint>
        <Hint
          label={i18n.t(
            'For best results use a square image, at a minimum 144x144 pixels up to a maximum of 400x400 pixels and less than 1MB.',
          )}
          variant={'default'}
        />
      </StyledHint>
    </div>
  );
};

interface FileDetails {
  path: string;
}

interface ImageDetails {
  height: number;
  width: number;
  name: string;
  size: number;
  dataUrl: string;
  file: FileDetails;
}

// eslint-disable-next-line import/prefer-default-export
export { WebClipIconUploader };
