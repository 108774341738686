import type { IconNames } from '@kandji-inc/nectar-ui';
import { Box, Flex, Icon, Text, Tooltip, styled } from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { i18n } from 'src/i18n';
import ArrowIcon from '../../assets/arrow.svg';
import { formatDate, getTimeBetween } from '../../helpers';

type TimelineItem = {
  label: string;
  type: string;
  date: string;
  icon: IconNames;
  theme?: 'default' | 'danger';
};

type TimelineProps = {
  items: TimelineItem[];
  showAge?: boolean;
  ageStartType?: string;
  ageEndType?: string;
};

const Label = styled(Text, {
  color: '$neutral70',
  fontSize: '$1',
});

const Time = styled(Text, {
  fontWeight: '$medium',
  fontSize: '$1',
});

const Timeline = (props: TimelineProps) => {
  const {
    items,
    showAge = false,
    ageStartType = undefined,
    ageEndType = undefined,
  } = props;

  const [isHoveringAge, setIsHoveringAge] = useState(false);

  const themes = {
    default: {
      color: 'var(--colors-neutral60)',
      backgroundColor: 'var(--colors-neutral10)',
    },
    danger: {
      color: 'var(--colors-red60)',
      backgroundColor: 'var(--colors-red10)',
    },
  };

  const getAlignment = (index: number) => {
    if (index === 0) return 'start';
    if (index === items.length - 1) return 'end';
    return 'center';
  };

  // Map of number of items in the timeline to the percentage of the gradient where the turning point is
  const itemPlacementPercentages = {
    3: [0, 50, 100],
    4: [0, 35, 65, 100],
  };

  // Map of number of items in the timeline to the percentage of the space between items
  const spaceBetweenItems = {
    3: 35,
    4: 20,
  };

  const ageStartIndex = items.findIndex((item) => item.type === ageStartType);
  const ageEndIndex = items.findIndex((item) => item.type === ageEndType);

  const getAgeWidth = /* istanbul ignore next */ () => {
    // Age start and end have an item between them
    if (ageEndIndex - ageStartIndex > 1) {
      return 50;
    }

    // Age start and end are next to each other
    return spaceBetweenItems[items.length];
  };

  return (
    <Flex flow="column" wFull>
      <Flex justifyContent="space-between" wFull css={{ zIndex: 1 }}>
        {items.map((item, idx) => {
          const { label, date, icon, theme = 'default' } = item;

          const { color, backgroundColor } = themes[theme];

          const isItemBetweenAgeStartAndEnd =
            idx > ageStartIndex && idx < ageEndIndex;
          const shouldHide =
            showAge && isItemBetweenAgeStartAndEnd && isHoveringAge;

          return (
            <Flex
              flow="column"
              gap="xs"
              alignItems={getAlignment(idx)}
              key={`${idx}-${label}`}
              css={{
                minWidth: '80px',
                visibility: shouldHide ? 'hidden' : 'visible',
              }}
            >
              <Label>{label}</Label>

              <Flex
                alignItems="center"
                justifyContent="center"
                css={{
                  backgroundColor,
                  borderRadius: '$round',
                  height: '20px',
                  width: '20px',
                }}
              >
                <Icon name={icon} color={color} size="xs" />
              </Flex>

              <Tooltip
                side="bottom"
                theme="dark"
                content={formatDate({ date, showUserFriendlyTimestamp: true })}
                css={{ zIndex: 3, maxWidth: 'auto' }}
              >
                <Time>{formatDate({ date })}</Time>
              </Tooltip>
            </Flex>
          );
        })}
      </Flex>

      {showAge && (
        <Box
          css={{
            position: 'relative',
            width: `${getAgeWidth()}%`,
            // Add 5 to account for the width of the age start timeline item
            left: `${itemPlacementPercentages[items.length][ageStartIndex] + 5}%`,
            bottom: '59px',
            height: '0px',
            zIndex: 2,
          }}
        >
          <Flex
            flow="column"
            alignItems="center"
            css={{
              height: '50px',
            }}
            onMouseEnter={
              /* istanbul ignore next */ () => setIsHoveringAge(true)
            }
            onMouseLeave={
              /* istanbul ignore next */ () => setIsHoveringAge(false)
            }
          >
            <Flex
              flow="column"
              alignItems="center"
              css={{
                gap: '8px',
                visibility: isHoveringAge ? 'visible' : 'hidden',
              }}
            >
              <Label>{i18n.$t('Age')}</Label>
              <img src={ArrowIcon} alt="Arrow" style={{ width: '10px' }} />
              <Label css={{ fontStyle: 'italic' }}>
                {getTimeBetween(
                  new Date(items[ageStartIndex].date),
                  new Date(items[ageEndIndex].date),
                )}
              </Label>
            </Flex>
          </Flex>
        </Box>
      )}

      <Flex css={{ position: 'relative', bottom: '30.5px', height: 0 }}>
        <Box
          css={{
            width: '100%',
            height: '1px',
            background: showAge
              ? `linear-gradient(to right, 
              $neutral30 ${itemPlacementPercentages[items.length][ageStartIndex]}%, 
              ${isHoveringAge ? '$neutral80' : '$neutral60'} ${itemPlacementPercentages[items.length][ageStartIndex]}%, 
              ${isHoveringAge ? '$neutral80' : '$neutral60'} ${itemPlacementPercentages[items.length][ageEndIndex]}%, 
              $neutral30 ${itemPlacementPercentages[items.length][ageEndIndex]}%)`
              : '$neutral30',
          }}
        />
      </Flex>
    </Flex>
  );
};

export type { TimelineItem };
export { Timeline };
