import deepcopy from 'deepcopy';
import KandjiDefaultIconDark from '../assets/kandji-icon-dark.png';
import KandjiDefaultIcon from '../assets/kandji-icon-logo-preview.png';
import { sfSymbolsRegularIcons } from '../icons';
import initialState from '../initial-state';
import imageToFile from './tofile';

const selfServiceFromApi = (data) => {
  const cloned = deepcopy(data);

  cloned.categories = cloned.categories.map((cat) => {
    const icon = sfSymbolsRegularIcons.find((ico) => ico.icon === cat.icon);
    return {
      ...cat,
      description: cat.description || '',
      iconImg: icon && icon.src,
    };
  });

  const defaultRecommended = {
    icon: 'star',
    name: 'Recommended',
    description: 'A list of recommended apps and tools.',
  };
  const defaultAll = {
    icon: 'square.grid.2x2',
    name: 'All',
    description: 'All applications and tools available through Self Service.',
  };

  cloned.recommended = {
    ...(cloned.recommended || defaultRecommended),
    iconImg:
      sfSymbolsRegularIcons.find((ico) => ico.icon === cloned.recommended?.icon)
        ?.src || defaultRecommended.src,
  };

  cloned.all = {
    ...(cloned.all || defaultAll),
    iconImg:
      sfSymbolsRegularIcons.find(
        (ico) => ico.icon === cloned.all?.icon || ico.icon === defaultAll.icon,
      )?.src || defaultAll.src,
  };

  cloned.branding_image_dark_s3key = cloned.branding_image_dark_s3key || null;
  cloned.branding_image_dark_url = cloned.branding_image_dark_url || null;

  const transformed = {
    categories: cloned.categories,
    recommended: cloned.recommended,
    all: cloned.all,
    branding: {
      header: cloned.branding_text || initialState.branding.header,
      subheader: cloned.branding_subtext || initialState.branding.subheader,
      logoUrl: cloned.branding_image_url || initialState.branding.logoUrl,
      logoS3Key: cloned.branding_image_s3key || initialState.branding.logoS3Key,
      darkLogoUrl:
        cloned.branding_image_dark_url || initialState.branding.darkLogoUrl,
      darkLogoS3Key:
        cloned.branding_image_dark_s3key || initialState.branding.darkLogoS3Key,
      defaultLogoFile: {
        dataUrl: KandjiDefaultIcon,
        file: imageToFile(KandjiDefaultIcon, 'kandji-default-icon'),
      },
      defaultLogoDarkFile: {
        dataUrl: KandjiDefaultIconDark,
        file: imageToFile(KandjiDefaultIconDark, 'kandji-default-dark-icon'),
      },
      isCustomLogo: !!cloned.branding_image_s3key,
      isCustomDarkLogo: !!cloned.branding_image_dark_s3key,
      // TODO: Check to see if logo is required to check initial customization
      isInitialCustomization: ![
        cloned.branding_text,
        cloned.branding_image_url,
      ].every(Boolean),
    },
  };

  return transformed;
};

const selfServiceToApi = (data) => {
  const cloned = deepcopy(data);

  cloned.categories.forEach((_, idx) => delete cloned.categories[idx].iconImg);
  delete cloned.all.iconImg;
  delete cloned.recommended.iconImg;

  cloned.branding_text = cloned.branding.header;
  cloned.branding_subtext = cloned.branding.subheader;
  cloned.branding_image_s3key = cloned.branding.logoS3Key;
  cloned.branding_image_dark_s3key = cloned.branding.darkLogoS3Key;

  delete cloned.branding;

  if (!cloned.migrations?.length) {
    delete cloned.migrations;
  }

  delete cloned.addEditCategory;

  return cloned;
};

export { selfServiceFromApi, selfServiceToApi };
