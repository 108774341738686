import { Flex, Heading, Icon, Link, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

import type { Vulnerability } from '../../vulnerability.types';

const Header = (props: { selectedVulnerability: Vulnerability }) => {
  const { selectedVulnerability } = props;
  const { cve_id, cve_link, app_name } = selectedVulnerability;

  return (
    <Flex
      justifyContent="start"
      gap="sm"
      alignItems="center"
      css={{ flexGrow: 1 }}
    >
      <Flex
        p2
        css={{
          backgroundColor: '$red10',
          borderRadius: '$round',
          height: '40px',
          width: '40px',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Icon name="malware" color="var(--colors-red60)" />
      </Flex>
      <Flex flow="column">
        <Flex gap="sm">
          <Heading size="3" css={{ fontWeight: '$medium' }}>
            {cve_id}
          </Heading>
        </Flex>
        <Flex gap="xs">
          {app_name && <Text css={{ color: '$neutral70' }}>{app_name}</Text>}
          {app_name && cve_link && <Text css={{ color: '$neutral70' }}>|</Text>}
          {cve_link && (
            <Link
              href={cve_link}
              target="_blank"
              rel="noopener noreferrer"
              css={{
                '&:hover': {
                  textDecorationColor: '$neutral70',
                },
              }}
            >
              <Flex flow="row" gap="xs">
                <Icon name="nvd" size="sm" />
                <Text css={{ color: '$neutral70' }}>
                  {i18n.t('Search NVD')}
                </Text>
              </Flex>
            </Link>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Header };
