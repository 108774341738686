// istanbul ignore file
import type { ZodSchema } from 'zod';
import { z } from 'zod';
import type { PrismCategorySchema } from './prism.types';

export const prismCategorySchema = z.object({
  display_name: z.string(),
  uri: z.string(),
  beta: z.boolean().optional(),
  paid: z.boolean().optional(),
  schema: z.object({
    properties: z.record(
      z.string(),
      z.object({
        title: z.string().optional(),
        type: z.string().optional(),
        format: z.string().optional(),
        $ref: z.string().optional(),
        allOf: z.array(z.object({ $ref: z.string() })).optional(),
      }),
    ),
    required: z.array(z.string()),
    definitions: z
      .record(
        z.string(),
        z.object({
          title: z.string(),
          enum: z.array(z.string()).nonempty(),
          type: z.string(),
        }),
      )
      .optional(),
  }),
});

export const prismCategoryDataSchema = z.array(prismCategorySchema);

export const prismCategoryParamsSchema = z.object({
  limit: z.number(),
  offset: z.number(),
  blueprint_ids: z.string().optional(),
  device_families: z.string().optional(),
  sort_by: z.string().nullable().optional(),
});

export const prismCountParamsSchema = z.object({
  blueprint_ids: z.string().optional(),
  device_families: z.string().optional(),
  category: z.string(),
});

export const prismCountResponseSchema = z.object({
  count: z.number(),
  approximate: z.boolean(),
});

export const prismCategoryFilterOperationMap = {
  equals: z.object({
    in: z.array(z.string()),
  }),
  does_not_equal: z.object({
    not_in: z.array(z.string()),
  }),
  contains: z.object({
    like: z.array(z.string()),
  }),
  does_not_contain: z.object({
    not_like: z.array(z.string()),
  }),
  in: z.object({
    in: z.array(z.string()),
  }),
  not_in: z.object({
    not_in: z.array(z.string()),
  }),
  bool_equals: z.object({
    eq: z.boolean(),
  }),
  bool_does_not_equal: z.object({
    dne: z.boolean(),
  }),
  num_equals: z.object({
    eq: z.number(),
  }),
  num_does_not_equal: z.object({
    dne: z.number(),
  }),
  num_greater_than: z.object({
    gt: z.number(),
  }),
  num_greater_than_equal: z.object({
    gte: z.number(),
  }),
  num_less_than: z.object({
    lt: z.number(),
  }),
  num_less_than_equal: z.object({
    lte: z.number(),
  }),
  date_and: z.object({
    lte: z.string(),
    gte: z.string(),
  }),
  date_or: z.object({
    or: z.object({
      gt: z.string(),
      lt: z.string(),
    }),
  }),
  date_greater_than: z.object({
    gt: z.date(),
  }),
  date_greater_than_equal: z.object({
    gte: z.date(),
  }),
  date_less_than: z.object({
    lt: z.date(),
  }),
  date_less_than_equal: z.object({
    lte: z.date(),
  }),
  is_null: z.object({
    is_null: z.boolean(),
  }),
};

export const prismCategoryStringFilterOperation = z.union([
  prismCategoryFilterOperationMap.equals,
  prismCategoryFilterOperationMap.does_not_contain,
  prismCategoryFilterOperationMap.contains,
  prismCategoryFilterOperationMap.does_not_contain,
  prismCategoryFilterOperationMap.is_null,
]);

export const prismCategoryEnumFilterOperation = z.union([
  prismCategoryFilterOperationMap.in,
  prismCategoryFilterOperationMap.not_in,
]);

export const prismCategoryBoolFilterOperation = z.union([
  prismCategoryFilterOperationMap.bool_equals,
  prismCategoryFilterOperationMap.bool_does_not_equal,
]);

export const prismCategoryNumFilterOperation = z.union([
  prismCategoryFilterOperationMap.num_equals,
  prismCategoryFilterOperationMap.num_does_not_equal,
  prismCategoryFilterOperationMap.num_greater_than,
  prismCategoryFilterOperationMap.num_greater_than_equal,
  prismCategoryFilterOperationMap.num_less_than,
  prismCategoryFilterOperationMap.num_less_than_equal,
]);

export const prismCategoryDateFilterOperation = z.union([
  prismCategoryFilterOperationMap.date_and,
  prismCategoryFilterOperationMap.date_or,
  prismCategoryFilterOperationMap.date_greater_than,
  prismCategoryFilterOperationMap.date_greater_than_equal,
  prismCategoryFilterOperationMap.date_less_than,
  prismCategoryFilterOperationMap.date_less_than_equal,
]);

export const deviceFamilyEnum = z.enum(['Mac', 'iPhone', 'iPad', 'AppleTV']);

export const prismViewSchema = z.object({
  id: z.string().uuid().optional(),
  name: z.string().optional(),
  category: z.string().optional(),
  columns: z
    .array(
      z.object({
        name: z.string().optional(),
        visible: z.boolean().optional(),
        size: z.number().optional(),
        category: z.string().optional(),
      }),
    )
    .optional(),
  filters: z.any().optional(),
  sort_by: z.string().nullable().optional(),
  created_at: z.string().datetime({ offset: true }).optional(),
  updated_at: z.string().datetime({ offset: true }).optional(),
});

export const prismViewResponseSchema = z.array(prismViewSchema);

export const metadata = z.object({
  total: z.number(),
  limit: z.number(),
  offset: z.number(),
});

export const jsonTypeToZod = (jsonType: string): ZodSchema => {
  switch (jsonType) {
    case 'string':
      return z.string();
    case 'boolean':
      return z.boolean();
    case 'integer':
      return z.number().int();
    case 'number':
    case 'time':
      return z.number();
    case 'date':
      return z.date();
    case 'array':
      return z.array(z.object({}));
    case 'object':
      return z.object({});
    default:
      return z.string();
  }
};

// TODO: better return type
export const parseSchemas = (schemas: PrismCategorySchema[]): any =>
  schemas.reduce((zodSchemas, schema) => {
    const zodSpec = {};
    const { properties, required, definitions } = schema.schema;

    Object.keys(properties).forEach((key) => {
      const field = properties[key];

      if (field?.type) {
        zodSpec[key] = jsonTypeToZod(field.type);
        // istanbul ignore next
      } else if (definitions && field && (field.$ref || field.allOf)) {
        const ref = (field.allOf ? field.allOf[0]?.$ref : field.$ref) || '';
        const fieldName = ref.split('/').pop();
        const fieldSchema = fieldName ? definitions[fieldName] : undefined;
        // istanbul ignore next
        if (fieldSchema) {
          if (fieldSchema.enum) {
            zodSpec[key] = z.enum(fieldSchema.enum);
          } else {
            zodSpec[key] = jsonTypeToZod(fieldSchema.type);
          }
        }
      }

      if (!required.includes(key) && zodSpec[key]) {
        zodSpec[key] = zodSpec[key].nullish();
      }
    });

    zodSchemas[schema.uri] = metadata.merge(
      z.object({ data: z.array(z.object(zodSpec)) }),
    );

    return zodSchemas;
  }, {});

export const deviceMetadataSchema = z.object({
  device_id: z.string().uuid(),
  device__name: z.string(),
  device__user_name: z.string(),
  device__user_id: z.string(),
  device__family: deviceFamilyEnum,
  model_id: z.string(),
  blueprint_id: z.string().uuid().nullable(),
  last_changed_at: z.string().nullable(),
  updated_at: z.string(),
});

export const categorySchema = z.object({});

export const exportPrismCategoryRequestSchema = z.object({
  blueprint_ids: z.array(z.string().uuid()),
  device_families: z.array(z.string()),
  category: z.string(),
  filter: z.any().optional(),
  columns: z.array(z.string()).optional(),
  sort_by: z.string().nullable(),
});

export const exportPrismCategoryResponseSchema = z.object({
  id: z.string().uuid(),
  status: z.enum(['pending', 'success', 'failed']),
  category: z.string(),
  args: z.any().optional(),
  err_msg: z.string().optional(),
  path: z.string().nullable(),
  signed_url: z.string().nullable(),
  created_at: z.string().datetime({ offset: true }),
  updated_at: z.string().datetime({ offset: true }),
});

export const exportPrismViewRequestSchema = z.object({
  blueprint_ids: z.array(z.string().uuid()).nullish(),
  device_families: z.array(z.string()).nullish(),
  view_id: z.string().nullish(),
  view_name: z.string().nullish(),
  columns: z.array(z.any()).nullish(),
  filter: z.any().nullish(),
});

export const exportPrismViewResponseSchema = z.object({
  id: z.string().uuid(),
  status: z.enum(['pending', 'success', 'failed']),
  view_id: z.string().nullish(),
  args: z.any().optional(),
  err_msg: z.string().optional(),
  path: z.string().nullable(),
  signed_url: z.string().nullable(),
  created_at: z.string().datetime({ offset: true }),
  updated_at: z.string().datetime({ offset: true }),
});

export const prismViewCountRequestSchema = z.object({
  blueprint_ids: z.array(z.string().uuid()).nullish(),
  device_families: z.array(z.string()).nullish(),
  view_id: z.string().nullish(),
  filter: z.any().nullish(),
  search: z.string().nullish(),
  columns: z.array(z.any()).nullish(),
});

export const prismViewCountResponseSchema = z.object({
  count: z.number(),
  approximate: z.boolean(),
});

export const customViewsOrderResponseSchema = z.object({
  order: z.string().transform((str) => str.split(',').map((s) => s.trim())),
});

export const customViewsOrderRequestSchema = z.object({
  order: z.array(z.string()),
});
