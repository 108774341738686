// istanbul ignore file
import {
  Chip,
  Flex,
  Grid,
  Heading,
  Icon,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { constants } from '../../common';
import ThreatListStatusBadge from '../../common/components/ThreatListStatusBadge';
import ThreatListTextCopyButton from '../../common/components/ThreatListTextCopyButton';
import getDate from '../../common/utils/getDate';
// TODO: Move file to common components
import ThreatListButtonPopover from '../../threat-groups/ThreatListByThreats/ThreatListSidePanel/ThreatListSidePanelDevices/ThreatListButtonPopover';
import type {
  BehavioralThreatClassification,
  BehavioralThreatDetail,
  BehavioralThreatStatus,
} from '../../threat.types';

const SplitViewClassificationIcon = styled(Flex, {
  width: 40,
  height: 40,
  padding: 6,
  borderRadius: '50%',
  svg: { width: 26, height: 26 },
  justifyContent: 'center',
  alignItems: 'center',
});

const ThreatNameHeading = styled(Heading, {
  fontSize: 20,
  lineHeight: '32px',
  letterSpacing: '-0.8px',
  fontWeight: '$medium',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 220,
});

const SummaryDetails = styled(Flex, {
  gap: '$3',
  alignItems: 'start',
});

const StyledGrid = styled(Grid, {
  fontSize: '$1',
  color: '$neutral70',
  gap: 6,
  gridTemplateColumns: '126px 1fr',
  flex: 1,
  alignItems: 'stretch',
  gridAutoRows: '18px',
});

const GridCell = styled(Flex, {
  gap: '$1',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const getClassificationLabel = (
  classification: BehavioralThreatClassification,
) => constants.THREAT_CLASSIFICATION_LABELS[classification];

const getClassifactionIcon = (classification: BehavioralThreatClassification) =>
  constants.THREAT_CLASSIFICATION_ICON_MAP[classification];

const getClassifactionColor = (
  classification: BehavioralThreatClassification,
) => constants.THREAT_CLASSIFICATION_COLOR[classification];

const getStatusLabel = (status: BehavioralThreatStatus) =>
  constants.THREAT_STATUS_DISPLAY_NAME_MAP[status];

const getStatusIcon = (status: BehavioralThreatStatus) =>
  constants.THREAT_STATUS_ICON_MAP[status];

const getStatusColor = (status: BehavioralThreatStatus) =>
  constants.THREAT_STATUS_COLOR_MAP[status];

type ThreatListSplitViewProps = {
  threat: BehavioralThreatDetail;
  timezone: string;
  tableSearchQuery: string | undefined;
  onReleaseThreat: () => void;
  onRecheckStatus: (threat: BehavioralThreatDetail) => void;
  isThreatBeingChecked: boolean;
};

const MoreTagsButton = ({
  tags,
  limit = 2,
}: { tags: string[]; limit?: number }) => {
  if (tags?.length === 0) return '-';

  return (
    <>
      {tags.slice(0, limit).map((item) => (
        <Chip label={item} size="compact" />
      ))}
      {tags.length > limit && (
        <ThreatListButtonPopover
          testId="split-panel-malware-family-button"
          trigger={<Chip label={`+${tags.length - limit}`} size="compact" />}
        >
          {tags.slice(limit).map((item) => (
            <Chip label={item} size="compact" />
          ))}
        </ThreatListButtonPopover>
      )}
    </>
  );
};

const ThreatListSplitViewHeader = (props: ThreatListSplitViewProps) => {
  const { threat, timezone, tableSearchQuery } = props;
  const color = getClassifactionColor(threat.classification);

  return (
    <Flex flow="column" gap="xl" wFull>
      <Flex
        flow="row"
        css={{
          alignItems: 'center',
          gap: '$2',
          justifyContent: 'space-between',
        }}
      >
        <SplitViewClassificationIcon css={{ bg: `$${color}10` }}>
          <Icon
            color={`var(--color-${color}-60)`}
            name={getClassifactionIcon(threat.classification)}
          />
        </SplitViewClassificationIcon>
        <Flex flex={1} flow="column" justifyContent="start" alignItems="start">
          <ThreatNameHeading as="h3">
            <ThreatListTextCopyButton
              text={threat.threat_id}
              tooltip={threat.threat_id}
            />
          </ThreatNameHeading>
          <Text variant="description">
            {getClassificationLabel(threat.classification)}
          </Text>
        </Flex>
      </Flex>
      <SummaryDetails>
        <StyledGrid>
          <GridCell>
            <Text variant="description">{i18n.t('Threat status')}</Text>
          </GridCell>
          <GridCell>
            <ThreatListStatusBadge
              color={getStatusColor(threat.threat_status)}
              icon={getStatusIcon(threat.threat_status)}
            >
              {getStatusLabel(threat.threat_status)}
            </ThreatListStatusBadge>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Detected on')}</Text>
          </GridCell>
          <GridCell>{getDate(threat.detection_date, timezone, true)}</GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Description')}</Text>
          </GridCell>
          <GridCell>{threat.description}</GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Rule version')}</Text>
          </GridCell>
          <GridCell>{threat.rule_version}</GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Malware family')}</Text>
          </GridCell>
          <GridCell>
            <MoreTagsButton tags={threat?.malware_families} />
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Informational tags')}</Text>
          </GridCell>
          <GridCell>
            <MoreTagsButton tags={threat?.informational_tags} />
          </GridCell>
        </StyledGrid>
      </SummaryDetails>
    </Flex>
  );
};

export default ThreatListSplitViewHeader;
