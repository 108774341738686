/* istanbul ignore file */
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';
import ContactSalesDialog from './ContactSalesDialog';
import LottieLoader from './LottieLoader';
import { convertToCurrencyFormat } from './common.data';

export const StepCounter = ({ count, max }) => {
  return (
    <Flex
      flex="1"
      gap="sm"
      css={{ position: 'absolute', bottom: '18px', width: '312px' }}
    >
      {new Array(max).fill(null).map((_, idx) => (
        <Box
          key={idx}
          css={{
            flex: '1',
            height: '16px',
            borderRadius: '4px',
            backgroundColor:
              idx + 1 <= count ? '$blue50' : 'rgba(161, 173, 196, 0.24)',
          }}
        />
      ))}
    </Flex>
  );
};

const StepOne = ({ model, onChange, onNext }) => {
  const title = i18n.t('Get a quote');

  const content = (
    <Flex flow="column" gap="xl" css={{ padding: '0 60px' }}>
      <Text>
        {i18n.t('Fill out the information below to receive a quote.')}
      </Text>
      <TextField
        placeholder={i18n.t('Enter number of devices')}
        label={i18n.t('How many Mac devices will you manage?')}
        value={model.macOsDeviceCount}
        onChange={(e) => onChange('macOsDeviceCount', e.target.value)}
        type="number"
        autoFocus
      />
      <TextField
        placeholder={i18n.t('Enter number of devices')}
        label={i18n.t(
          'How many iPhone, iPad, or Apple TV devices will you manage?',
        )}
        value={model.iosAppleTvDeviceCount}
        onChange={(e) => onChange('iosAppleTvDeviceCount', e.target.value)}
        type="number"
      />
      <Button variant="primary" onClick={onNext}>
        {i18n.t('Next')}
      </Button>
      <StepCounter max={3} count={1} />
    </Flex>
  );

  return [title, content];
};

const StepTwo = ({
  model,
  onChange,
  onNext,
  onPrev,
  generateQuote,
  isLoadingQuote,
  onToggleContactSales,
}) => {
  const { isEdr } = model;
  const isEdrBoolean = typeof isEdr === 'boolean';

  const title = i18n.t('Get a quote');

  const content = isLoadingQuote ? (
    <Flex
      flow="column"
      alignItems="center"
      justifyContent="center"
      css={{ height: '75%' }}
    >
      <LottieLoader />
      <Text>{i18n.t('Generating your quote...')}</Text>
      <StepCounter max={3} count={2} />
    </Flex>
  ) : (
    <Flex
      flow="column"
      gap="xl"
      alignItems="center"
      css={{ padding: '0 60px' }}
    >
      <Text>
        {i18n.t('Fill out the information below to receive a quote.')}
      </Text>
      <Flex flow="column" gap="lg">
        <Text>
          {i18n.t(
            'Are you interested in Endpoint Detection and Response to increase the security posture of your Mac fleet?',
          )}
        </Text>
        <ButtonGroup
          compact
          css={{
            '& > button': {
              flex: 1,
            },
          }}
          variant="input"
          buttons={[
            {
              label: i18n.t('Yes'),
              onClick: () => onChange('isEdr', true),
              selected: isEdr,
            },
            {
              label: i18n.t('No'),
              onClick: () => onChange('isEdr', false),
              selected: isEdr === false,
            },
          ]}
        />
        {isEdr && <Text>{i18n.t("Great! We'll add EDR to your quote.")}</Text>}
        {isEdr === false && (
          <Text>
            {i18n.t(
              'No problem! You can always add this later if you change your mind.',
            )}
          </Text>
        )}
      </Flex>
      <Flex flow="column" gap="lg" css={{ width: '100%' }}>
        <Button
          variant="primary"
          onClick={() =>
            generateQuote()
              .then(onNext)
              .catch(() =>
                onToggleContactSales({
                  error: i18n.t(
                    'There was an issue generating your quote. Please contact sales using the form below.',
                  ),
                }),
              )
          }
        >
          {isEdrBoolean ? i18n.t('View quote') : i18n.t('Next')}
        </Button>
        <Button variant="subtle" onClick={onPrev}>
          {i18n.t('Back')}
        </Button>
        <StepCounter max={3} count={2} />
      </Flex>
    </Flex>
  );

  return [title, content];
};

const StepThree = ({ onPrev, quoteData, locale, onToggleContactSales }) => {
  const {
    macOsUnitPrice,
    iosAppleTvDeviceUnitPrice,
    edrEnabled,
    iosAppleTvDeviceCount,
    macOsDeviceCount,
    quoteContractTermMonths,
    edrPriceTotal,
    quoteTotal,
    quoteTotalCurrency,
    macOsPriceTotal,
    iosAppleTvPriceTotal,
    quoteUrl,
  } = quoteData;

  const title = i18n.t('Your quote is');

  const content = (
    <Flex
      flow="column"
      gap="xl"
      alignItems="center"
      css={{ padding: '0 60px' }}
    >
      <Flex flow="column" gap="xs" alignItems="center">
        <Icon name="kandji-logo" />
        <Heading size="1" css={{ position: 'relative' }}>
          {convertToCurrencyFormat(quoteTotal, 2, locale, quoteTotalCurrency)}{' '}
          <Flex
            gap="xs"
            css={{ position: 'absolute', right: '-33px', top: '7px' }}
          >
            {/* <Button variant="subtle" compact icon={{ name: 'download' }} /> */}
            <Button
              variant="subtle"
              compact
              icon={{ name: 'pencil' }}
              onClick={onPrev}
            />
          </Flex>
        </Heading>
        <Text>{i18n.t('for annual contract')}</Text>
      </Flex>
      <Flex
        flow="column"
        gap="lg"
        css={{
          backgroundColor: '$blue05',
          width: '480px',
          padding: '0 65px',
        }}
      >
        <Flex gap="md" flow="column" p4 css={{ gap: '6px' }}>
          <Flex justifyContent="space-between" css={{ width: '100%' }}>
            <Flex css={{ gap: '6px' }} flex="1">
              <Icon
                size="sm"
                name="sf-desktop-computer"
                style={{ width: '14px', height: '14px' }}
              />
              <Box>
                <Text size="1" css={{ fontWeight: 500 }}>
                  {i18n.t('{macOsDeviceCount} Macs', { macOsDeviceCount })}
                </Text>
                <Text size="1" variant="secondary">
                  {i18n.t(
                    '${macOsUnitPrice}/device x {quoteContractTermMonths} months',
                    {
                      macOsUnitPrice,
                      quoteContractTermMonths,
                    },
                  )}
                </Text>
                {edrEnabled && (
                  <Text size="1" variant="secondary" css={{ marginTop: '6px' }}>
                    {i18n.t('+ Endpoint Detection and Response')}
                  </Text>
                )}
              </Box>
            </Flex>

            <Flex
              flow="column"
              justifyContent="space-between"
              css={{ textAlign: 'end' }}
            >
              <Text size="1">
                {convertToCurrencyFormat(
                  macOsPriceTotal,
                  0,
                  locale,
                  quoteTotalCurrency,
                )}
              </Text>
              {Boolean(edrPriceTotal) && (
                <Text size="1">
                  {convertToCurrencyFormat(
                    edrPriceTotal,
                    0,
                    locale,
                    quoteTotalCurrency,
                  )}
                </Text>
              )}
            </Flex>
          </Flex>

          <Flex justifyContent="space-between" css={{ width: '100%' }}>
            <Flex css={{ gap: '6px' }} flex="1">
              <Icon
                size="sm"
                name="sf-iphone"
                style={{ width: '14px', height: '14px' }}
              />
              <Box>
                <Text size="1" css={{ fontWeight: 500 }}>
                  {i18n.t('{iosAppleTvDeviceCount} iPhone/iPad/Apple TV', {
                    iosAppleTvDeviceCount,
                  })}
                </Text>
                <Text size="1" variant="secondary">
                  {i18n.t(
                    '${iosAppleTvDeviceUnitPrice}/device x {quoteContractTermMonths} months',
                    {
                      iosAppleTvDeviceUnitPrice,
                      quoteContractTermMonths,
                    },
                  )}
                </Text>
              </Box>
            </Flex>

            <Text size="1" css={{ textAlign: 'end' }}>
              {convertToCurrencyFormat(
                iosAppleTvPriceTotal,
                0,
                locale,
                quoteTotalCurrency,
              )}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex flow="column" gap="sm">
        <Text>{i18n.t('Ready to manage the rest of your fleet?')}</Text>
        <Button
          variant="primary"
          icon={{ name: 'signature' }}
          onClick={() => window.open(quoteUrl, '_blank')}
        >
          {i18n.t('Sign now')}
        </Button>

        <Flex justifyContent="center" alignItems="center" gap="xs">
          <Text>{i18n.t('or')} </Text>
          <Text
            size="1"
            variant="primary"
            css={{ cursor: 'pointer', fontWeight: 500 }}
            onClick={onToggleContactSales}
          >
            {i18n.t('contact sales')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );

  return [title, content];
};

export const quoteSteps = {
  1: StepOne,
  2: StepTwo,
  3: StepThree,
};
