import { useDroppable } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { Flex, Grid } from '@kandji-inc/nectar-ui';
import React, { useMemo } from 'react';
import type { MutableRefObject } from 'react';

import { homeScreenLayoutItemSchema } from '../../../home-screen-layout.schemas';
import type {
  HomeScreenLayoutDeviceKind,
  HomeScreenLayoutItem,
  HomeScreenLayoutPreviewContextKind,
  NonFolderHomeScreenLayoutItem,
  PreviewStyleKind,
  UpdateFn,
} from '../../../home-screen-layout.types';
import { PREVIEW_CONFIG, createDragIndicatorCss } from '../../common';
import SingleApp from '../../common/SingleApp';
import bezelIpadLandscape from '../../common/assets/bezel-ipad-landscape.svg';
import bezelIpadPortrait from '../../common/assets/bezel-ipad-portrait.svg';
import bezelIphonePortrait from '../../common/assets/bezel-iphone.svg';
import Folder from '../Folder';

const deviceGridSize = {
  iphone_portrait: {
    columns: '4',
    rows: '6',
  },
  ipad_portrait: {
    columns: '5',
    rows: '6',
  },
  ipad_landscape: {
    columns: '6',
    rows: '5',
  },
};

const devicePadding = {
  ipad_portrait: '$8 $6 $10 $6',
  ipad_landscape: '$7 $6 $9 $6',
  iphone_portrait: '$8 $6 $9 $6',
};

const bezelImages = {
  ipad_portrait: bezelIpadPortrait,
  ipad_landscape: bezelIpadLandscape,
  iphone_portrait: bezelIphonePortrait,
};

export type PageProps = {
  readonly kind: HomeScreenLayoutDeviceKind;
  readonly pageIndex: number;
  readonly currentPage: number;
  readonly page: Array<HomeScreenLayoutItem | NonFolderHomeScreenLayoutItem>;
  readonly disabled: boolean;
  readonly combinedRefs?: MutableRefObject<{
    droppableContainerRef?: HTMLDivElement | null;
    pageContainerRef?: HTMLDivElement | null;
  }>;
  pageContainerRef: MutableRefObject<HTMLDivElement>;
  readonly orientationStyle: PreviewStyleKind;
  readonly currentFolderPage: number;
  readonly isAtMaxPerFolder: boolean;
  readonly onRemove: (
    item: HomeScreenLayoutItem,
    ctx: {
      kind: HomeScreenLayoutDeviceKind;
      context: HomeScreenLayoutPreviewContextKind;
      disabled: boolean;
      isRemovable: boolean;
    },
  ) => void;
  readonly onSelectApp?: (app: any) => void;
  selectedApps: Array<HomeScreenLayoutItem | NonFolderHomeScreenLayoutItem>;
  readonly update: UpdateFn;
};

const Page = (props: PageProps) => {
  const {
    kind,
    pageIndex,
    currentPage,
    page,
    disabled,
    combinedRefs = null,
    orientationStyle,
    pageContainerRef,
    currentFolderPage,
    isAtMaxPerFolder,
    onRemove,
    onSelectApp,
    selectedApps,
    update,
  } = props;

  const previewConfig = PREVIEW_CONFIG[kind];
  const {
    pageDroppableContainerId,
    prefixSortablePageItemId: prefixIdSortablePageItem,
    prefixSortablePageFolderId: prefixIdSortablePageFolder,
    dndContext: { droppablePage },
  } = PREVIEW_CONFIG.common;
  const droppablePageId = pageDroppableContainerId;
  const isAtMax = page?.length >= previewConfig.maxPerPage;
  const droppablePageUniqueId = `${droppablePageId}_${pageIndex}`;

  const { setNodeRef, active, over } = useDroppable({
    id: droppablePageUniqueId,
    data: {
      dndContext: droppablePage,
      items: page,
      maxItems: previewConfig.maxPerPage,
      isAtMax,
      pageIndex,
    },
    disabled: disabled || isAtMax || Boolean(currentFolderPage),
  });

  const pageContainerCss = useMemo(() => {
    /* istanbul ignore next */
    return {
      ...getPreviewDimensions(kind, orientationStyle),
    };
  }, [kind, orientationStyle]);

  return (
    <SortableContext
      id={droppablePageUniqueId}
      items={page.map((item) =>
        item.Type === 'Folder'
          ? `${prefixIdSortablePageFolder}${item.id}`
          : `${prefixIdSortablePageItem}${item.id}`,
      )}
      strategy={rectSortingStrategy}
      disabled={disabled}
    >
      <Flex
        ref={(r) => {
          setNodeRef(r);
          if (combinedRefs?.current) {
            combinedRefs.current.droppableContainerRef = r;
          }

          if (pageIndex === 0) {
            pageContainerRef.current = r;

            if (combinedRefs?.current) {
              combinedRefs.current.pageContainerRef = r;
            }
          }
        }}
        justifyContent="center"
        alignItems="center"
        flow="column"
        css={{
          ...pageContainerCss,
          padding: devicePadding[`${kind}_${orientationStyle}`],
          transition: 'opacity 250ms ease',
          willChange: 'opacity',
          opacity: `${currentPage === pageIndex ? '1' : '0.5'}`,
          backgroundImage: `url(${bezelImages[`${kind}_${orientationStyle}`]})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Grid
          as="section"
          aria-label={`${kind === 'ipad' ? 'iPad' : 'iPhone'} Preview Grid`}
          css={{
            width: '100%',
            height: '100%',
            placeItems: 'center',
          }}
          {...deviceGridSize[`${kind}_${orientationStyle}`]}
        >
          {page.map((item, itemIndex) => {
            const parsed = homeScreenLayoutItemSchema.safeParse(item);
            /* istanbul ignore next */
            if (parsed.success) {
              switch (parsed.data.Type) {
                case 'Application': {
                  const {
                    name,
                    BundleID,
                    icon,
                    id,
                    URL,
                    Type,
                    appType,
                    deviceFamilies,
                    alwaysInPreview,
                  } = parsed.data;

                  const dragIndicatorCss = createDragIndicatorCss({
                    pageOrFolderItems: page,
                    currentPageOrFolderPageNumber: currentPage,
                    pageOrFolderIndex: pageIndex,
                    itemIndex,
                    active,
                    over,
                    droppableContainerId: droppablePageUniqueId,
                    sortableItemId: `${prefixIdSortablePageItem}${id}`,
                  });

                  const isRemovable =
                    typeof alwaysInPreview === 'boolean'
                      ? !alwaysInPreview
                      : true;

                  return (
                    <SingleApp
                      key={id}
                      kind={kind}
                      context="page"
                      id={id}
                      URL={URL}
                      name={name}
                      BundleID={BundleID}
                      alwaysInPreview={alwaysInPreview}
                      icon={icon}
                      Type={Type}
                      appType={appType}
                      deviceFamilies={deviceFamilies}
                      onRemove={onRemove}
                      isRemovable={isRemovable}
                      disabled={disabled}
                      items={page}
                      maxItems={previewConfig.maxPerPage}
                      isAtMax={isAtMax}
                      isAtMaxPerFolder={isAtMaxPerFolder}
                      className={dragIndicatorCss()}
                      onSelect={(app) => {
                        // Prevent selection on non-current pages:
                        if (currentPage === pageIndex) {
                          onSelectApp(app);
                        }
                      }}
                      isSelected={selectedApps.some((app) => app.id === id)}
                      pageIndex={pageIndex}
                    />
                  );
                }

                case 'WebClip': {
                  const {
                    name,
                    icon,
                    id,
                    Type,
                    appType,
                    deviceFamilies,
                    alwaysInPreview,
                  } = parsed.data;

                  const dragIndicatorCss = createDragIndicatorCss({
                    pageOrFolderItems: page,
                    currentPageOrFolderPageNumber: currentPage,
                    pageOrFolderIndex: pageIndex,
                    itemIndex,
                    active,
                    over,
                    droppableContainerId: droppablePageUniqueId,
                    sortableItemId: `${prefixIdSortablePageItem}${id}`,
                  });

                  const isRemovable =
                    typeof alwaysInPreview === 'boolean'
                      ? !alwaysInPreview
                      : true;

                  return (
                    <SingleApp
                      key={id}
                      kind={kind}
                      context="page"
                      id={id}
                      name={name}
                      alwaysInPreview={alwaysInPreview}
                      icon={icon}
                      Type={Type}
                      URL={parsed.data.URL}
                      appType={appType}
                      deviceFamilies={deviceFamilies}
                      onRemove={onRemove}
                      isRemovable={isRemovable}
                      disabled={disabled}
                      items={page}
                      maxItems={previewConfig.maxPerPage}
                      isAtMax={isAtMax}
                      isAtMaxPerFolder={isAtMaxPerFolder}
                      className={dragIndicatorCss()}
                      onSelect={(app) => {
                        // Prevent selection on non-current pages:
                        if (currentPage === pageIndex) {
                          onSelectApp(app);
                        }
                      }}
                      isSelected={selectedApps.some((app) => app.id === id)}
                      pageIndex={pageIndex}
                    />
                  );
                }

                case 'Folder': {
                  const { DisplayName, id, Pages } = parsed.data;
                  const folderApps = Pages[
                    currentFolderPage || 0
                  ] as readonly NonFolderHomeScreenLayoutItem[];

                  const dragIndicatorCss = createDragIndicatorCss({
                    pageOrFolderItems: page,
                    currentPageOrFolderPageNumber: currentPage,
                    pageOrFolderIndex: pageIndex,
                    itemIndex,
                    active,
                    over,
                    droppableContainerId: droppablePageUniqueId,
                    sortableItemId: `${prefixIdSortablePageFolder}${id}`,
                  });

                  return (
                    <Folder
                      key={id}
                      id={id}
                      kind={kind}
                      apps={folderApps}
                      allPageItems={page}
                      name={DisplayName}
                      disabled={disabled}
                      onRemove={onRemove}
                      update={update}
                      combinedRefs={combinedRefs}
                      className={dragIndicatorCss()}
                      pageIndex={pageIndex}
                    />
                  );
                }

                default:
                  /* istanbul ignore next */
                  return null;
              }
            }

            /* istanbul ignore next */
            return null;
          })}
        </Grid>
      </Flex>
    </SortableContext>
  );
};

const getPreviewDimensions = (
  kind: HomeScreenLayoutDeviceKind,
  style: PreviewStyleKind,
) => {
  const { ipad, iphone } = PREVIEW_CONFIG;

  /* istanbul ignore next */
  switch (kind) {
    case 'iphone':
      return {
        minWidth: iphone.pageWidth.portrait,
        minHeight: iphone.pageHeight.portrait,
      };
    case 'ipad':
      return style === 'portrait'
        ? {
            minWidth: ipad.pageWidth.portrait,
            minHeight: ipad.pageHeight.portrait,
          }
        : {
            minWidth: ipad.pageWidth.landscape,
            minHeight: ipad.pageHeight.landscape,
          };
    default:
      return {};
  }
};

export default Page;
