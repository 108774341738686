import { Button, Flex } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import EDRDetectionDateFilter from 'src/features/edr/common/components/Filters/EDRDetectionDateFilter';
import EDRSearchFilter from 'src/features/edr/common/components/Filters/EDRSearchFilter';
import { useShallow } from 'zustand/react/shallow';
import { SeverityMultiSelect } from '../../common/table/filters/severity-multi-select';
import {
  DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS,
  defaultDateFilter,
  defaultDeviceVulnerabilitiesFilter,
} from '../../constants';
import useVulnerability from '../../store';

const FilterBar = () => {
  const [filter, setFilter] = useVulnerability(
    useShallow((state) => [
      state.deviceVulnerabilitiesFilter,
      state.setDeviceVulnerabilitiesFilter,
    ]),
  );

  const [debouncedSearchValue, setSearchValue, searchValue] =
    useDebouncedState<string>('', 250);

  useEffect(
    /* istanbul ignore next*/ () => {
      setFilter({ term: debouncedSearchValue });
    },
    [debouncedSearchValue],
  );

  useEffect(
    /* istanbul ignore next */ () => {
      setSearchValue(filter.term);
    },
    [filter],
  );

  useEffect(() => {
    return () => setFilter(defaultDeviceVulnerabilitiesFilter);
  }, []);

  return (
    <Flex p3 gap="md" css={{ paddingLeft: '$5' }}>
      <EDRSearchFilter
        name="vulnerability"
        value={searchValue}
        onChange={(_, value) => setSearchValue(value as string)}
        onClear={() => setSearchValue('')}
        placeholder={i18n.t('Search ID and Application')}
      />

      <SeverityMultiSelect
        severity={filter.severity}
        setSeverity={(value) => setFilter({ severity: value })}
      />

      <EDRDetectionDateFilter
        filter={filter.detectionDate}
        label="Detection date"
        options={DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
        onChange={
          /* istanbul ignore next */ (_, fields: DetectionDateFilterFields) =>
            setFilter({ detectionDate: fields })
        }
        onClear={
          /* istanbul ignore next */ () =>
            setFilter({ detectionDate: defaultDateFilter })
        }
      />

      {!isEqual(filter, defaultDeviceVulnerabilitiesFilter) && (
        <Button
          variant="subtle"
          compact
          onClick={() => setFilter(defaultDeviceVulnerabilitiesFilter)}
        >
          {i18n.t('Clear all')}
        </Button>
      )}
    </Flex>
  );
};

export { FilterBar };
