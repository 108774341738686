/* istanbul ignore file */
import { i18n } from 'i18n';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';

export const severityColorMap = {
  critical: '$red60',
  high: '$red50',
  medium: '$yellow60',
  low: '$yellow50',
  none: '$green50',
};

export const DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS: {
  getLabel: () => string;
  getInlineLabel?: () => string;
  value: string;
}[] = [
  {
    getLabel: () => i18n.t('Last 24 hours'),
    getInlineLabel: () => i18n.t('last 24 hours'),
    value: '24hours',
  },
  {
    getLabel: () => i18n.t('Last 7 days'),
    getInlineLabel: () => i18n.t('last 7 days'),
    value: '7days',
  },
  {
    getLabel: () => i18n.t('Last 30 days'),
    getInlineLabel: () => i18n.t('last 30 days'),
    value: '30days',
  },
  {
    getLabel: () => i18n.t('Last 60 days'),
    getInlineLabel: () => i18n.t('last 60 days'),
    value: '60days',
  },
  {
    getLabel: () => i18n.t('Last 90 days'),
    getInlineLabel: () => i18n.t('last 90 days'),
    value: '90days',
  },
  {
    getLabel: () => i18n.t('All time'),
    value: 'all_time',
  },
  {
    getLabel: () => i18n.t('Custom date range'),
    value: 'custom_date_range',
  },
] as const;

export const DEFAULT_DETECTION_DATE_FILTER = { value: '30days' };

export const defaultDateFilter: DetectionDateFilterFields = {
  value: '',
  operator: '',
  dateRangeFrom: '',
  dateRangeTo: '',
  selectedDate: '',
};

export const defaultAllVulnerabilitiesFilter = {
  term: '',
  severity: [],
  firstDetected: null,
  latestDetected: DEFAULT_DETECTION_DATE_FILTER,
};

export const defaultDeviceVulnerabilitiesFilter = {
  term: '',
  severity: [],
  detectionDate: DEFAULT_DETECTION_DATE_FILTER,
};
