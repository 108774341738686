import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  DateTimeCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from './column-utils';
import { getGlobalColumns } from './globalColumns';

export const getTransparencyDatabaseColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const transparencyDatabaseColumnHelper =
    createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(
    schema,
    transparencyDatabaseColumnHelper,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    transparencyDatabaseColumnHelper.accessor((row) => row.application, {
      id: 'application',
      meta: {
        displayName: 'Application',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.service, {
      id: 'service',
      meta: {
        displayName: 'Service',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.status, {
      id: 'status',
      meta: {
        displayName: 'Status',
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          schema.schema?.definitions.Status.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.status_reason, {
      id: 'status_reason',
      meta: {
        displayName: 'Status Reason',
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          schema.schema?.definitions.StatusReason.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.local_user, {
      id: 'local_user',
      meta: {
        displayName: 'Local User',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.client_type, {
      id: 'client_type',
      meta: {
        defaultHidden: true,
        displayName: 'Application Identifier Type',
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          schema.schema?.definitions.ClientType.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.last_modified, {
      id: 'last_modified',
      cell: DateTimeCell,
      meta: {
        defaultHidden: true,
        displayName: 'Last Modified',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
