import {
  Box,
  Code,
  Flex,
  Icon,
  type IconNames,
  Link,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useState } from 'react';
import EDRCopyButton from 'src/features/edr/common/components/EDRCopyButton';
import { Timeline, type TimelineItem } from '../../components/timeline';
import type { Vulnerability } from '../../vulnerability.types';

const DetailRow = styled(Flex, {
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$3',
});

const DetailKey = styled(Text, {
  color: '$neutral70',
  fontSize: '$1',
  width: '120px',
});

const Body = (props: { vulnerability: Vulnerability }) => {
  const { vulnerability } = props;
  const {
    cve_link,
    cve_modified_at,
    cve_published_at,
    description,
    detection_datetime,
    app_path,
    app_version,
  } = vulnerability;

  const [isHoveringPath, setIsHoveringPath] = useState(false);

  const timelineItems: TimelineItem[] = [
    {
      label: i18n.t('Published on'),
      type: 'published',
      date: cve_published_at,
      theme: 'default',
      icon: 'file-plus',
    } as TimelineItem,
    {
      label: i18n.t('Detection date'),
      type: 'detection',
      date: detection_datetime,
      theme: 'danger',
      icon: 'bell-exclamation',
    } as TimelineItem,
    ...(cve_modified_at
      ? [
          {
            label: i18n.t('Modified on'),
            type: 'modified',
            date: cve_modified_at,
            theme: 'default',
            icon: 'pen-field' as IconNames,
          } as TimelineItem,
        ]
      : []),
  ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return (
    <Flex flow="column" gap="lg">
      <Timeline items={timelineItems} showAge={false} />

      <Box wFull css={{ height: '1px', backgroundColor: '$neutral20' }} />

      <Flex flow="column" gap="xs">
        <Text css={{ fontWeight: '$medium', fontSize: '$1' }}>
          {i18n.t('Description')}
        </Text>
        <Text css={{ fontSize: '$1', color: '$neutral70' }}>{description}</Text>
      </Flex>

      <Flex flow="column" gap="sm">
        <DetailRow>
          <DetailKey>{i18n.t('NVD')}</DetailKey>
          <Link
            href={cve_link}
            target="_blank"
            rel="noopener noreferrer"
            css={{
              '&:hover': {
                textDecorationColor: '$neutral90',
              },
            }}
          >
            <Flex flow="row" gap="xs">
              <Icon name="nvd" size="sm" />
              <Text
                css={{
                  fontWeight: '$medium',
                  fontSize: '$1',
                  color: '$neutral90',
                }}
              >
                {i18n.t('Search NVD')}
              </Text>
            </Flex>
          </Link>
        </DetailRow>

        <DetailRow
          onMouseEnter={() => setIsHoveringPath(true)}
          onMouseLeave={() => setIsHoveringPath(false)}
        >
          <DetailKey>{i18n.t('Path')}</DetailKey>
          <Flex gap="xs">
            <Code
              css={{
                fontSize: '$1',
                lineHeight: '$1',
                maxWidth: '250px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {app_path}
            </Code>
            {isHoveringPath && (
              <EDRCopyButton
                value={app_path}
                testId="path-copy-button"
                css={{ zIndex: 2 }}
              />
            )}
          </Flex>
        </DetailRow>

        <DetailRow>
          <DetailKey>{i18n.t('Version')}</DetailKey>
          <Text>{app_version}</Text>
        </DetailRow>
      </Flex>
    </Flex>
  );
};

export { Body };
