import {
  baseLibraryItemFromApi,
  baseLibraryItemToApi,
} from 'src/features/library-items/data-service/library-item/transformers';
import { apiTypes } from 'src/features/library-items/library/common';
import type {
  IpaAppApiData,
  IpaAppFromApi,
  IpaAppModel,
} from '../in-house-apps.types';

async function transformFromApi(
  apiData: IpaAppApiData,
): Promise<IpaAppFromApi> {
  const {
    file,
    sha256,
    icon,
    file_name: fileName,
    file_size: fileSize,
    file_updated: fileUpdated,
    file_url: fileUrl,
    file_key: fileKey,
    app_identifier: appId,
    app_version: appVersion,
    app_icon: appIcon,
    ipad_icon: ipadIcon,
    app_name: appName,
  } = apiData;

  const baseFromApi = await baseLibraryItemFromApi(apiData);

  return {
    ...apiData,
    data: {
      ...baseFromApi,
      icon,
      installDetails: {
        ipaFile: {
          file,
          fileName,
          fileSize,
          fileKey,
          fileUrl,
          fileUpdated,
          appId,
          appVersion,
          sha256,
          appIcon,
          ipadIcon,
          appName,
        },
      },
    },
  };
}

function transformToApi(model: IpaAppModel): Partial<IpaAppApiData> {
  const { installDetails } = model;
  const { file } = installDetails.ipaFile;

  const baseToApi = baseLibraryItemToApi(model);

  return {
    ...baseToApi,
    type: apiTypes.IPA_APP_V2,
    file,
    file_key: file,
    data: { file_url: '', app_icon: '' },
  };
}

export { transformFromApi, transformToApi };
