/* istanbul ignore file */

const initialState = {
  id: '',
  name: '',
  isActive: true,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  devices: [],
  rules: [],
  icon: undefined,
  installDetails: {
    ipaFile: {
      file: undefined,
      fileName: undefined,
      fileSize: undefined,
      fileKey: undefined,
      fileUpdated: undefined,
      fileUrl: undefined,
      appIcon: undefined,
      ipadIcon: undefined,
      appId: undefined,
      appName: undefined,
      appVersion: undefined,
      sha256: undefined,
    },
  },
};

export default initialState;
