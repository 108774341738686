/* istanbul ignore file */
import { Dialog } from '@kandji-inc/nectar-ui';
import deepcopy from 'deepcopy';
import React, { useContext, useEffect, useState } from 'react';
import { AccountContext } from 'src/contexts/account';
import { quoteSteps } from './quote-steps';
import { useGenerateQuote } from './useGenerateQoute';

const defaultModel = {
  macOsDeviceCount: null,
  iosAppleTvDeviceCount: null,
  isEdr: null,
};

const QuoteDialog = ({
  onClose,
  onToggleContactSales,
  openOnDetails = undefined,
  openWithDetails = undefined,
  details = undefined,
}) => {
  const { currentCompany } = useContext(AccountContext);
  const locale = currentCompany.locale;

  const [model, setModel] = useState(deepcopy(defaultModel));
  const [step, setStep] = useState(openOnDetails ? 3 : 1);

  const { iosAppleTvDeviceCount, macOsDeviceCount, isEdr } = model;
  const {
    mutateAsync: generateQuote,
    data: quoteData,
    isPending,
  } = useGenerateQuote();

  const onChange = (key, value) =>
    setModel((prev) => ({ ...prev, [key]: value }));
  const onNext = () => setStep((prev) => prev + 1);
  const onPrev = () => setStep((prev) => prev - 1);

  const [title, content, footer] = quoteSteps[step]({
    model,
    onChange,
    onNext,
    onPrev,
    onClose,
    onToggleContactSales,
    generateQuote: () =>
      generateQuote({
        numberOfIosDevices: parseInt(iosAppleTvDeviceCount, 10),
        numberOfMacDevices: parseInt(macOsDeviceCount, 10),
        isEdrEnabled: isEdr,
      }),
    isLoadingQuote: isPending,
    quoteData: quoteData || details,
    locale,
  });

  useEffect(() => {
    if (openOnDetails || openWithDetails) {
      setModel({
        macOsDeviceCount: details.macOsDeviceCount,
        iosAppleTvDeviceCount: details.iosAppleTvDeviceCount,
        isEdr: details.edrEnabled,
      });
    }
  }, [openOnDetails, openWithDetails]);

  return (
    <Dialog
      css={{
        width: '480px',
        height: '450px',
      }}
      headerCss={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '32px',
        paddingLeft: '28px',
      }}
      bodyCss={{
        height: '100%',
      }}
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onClose}
      title={title}
      content={content}
      footer={footer}
    />
  );
};

export default QuoteDialog;
