/* istanbul ignore file */
import { useActivityMonitor } from 'app/api/activity';
import { links, tabsNames } from 'app/common/constants';
import { InterfaceContext } from 'contexts/interface';
import { i18n } from 'i18n';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {
  memo,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setPageScroll } from 'src/app/_actions/computer';
import { useFlags } from 'src/config/feature-flags';
import { AccountContext } from 'src/contexts/account';
import FooterItems from './FooterItems';
import SidebarItemLink from './SidebarItemLink';
import { startAlertMonitor, stopAlertMonitor } from './alerts';

const Item = memo(({ item, withScrollBar }) => {
  const { sidebarOpened } = useContext(InterfaceContext);
  const currentParentPage = useLocation().pathname;

  const isActive = useMemo(() => {
    const parentTab = `/${get(item.link.split('/'), '[1]')}`;

    // wtf?
    return (
      (currentParentPage.indexOf(parentTab || item.link) === 0 &&
        item.link !== links.index) ||
      ([links.index, links.computers, links.devices].includes(
        currentParentPage,
      ) &&
        item.link === links.devices) ||
      (links.index === currentParentPage && item.link === links.index)
    );
  }, [item.link, currentParentPage]);

  return (
    <SidebarItemLink
      active={isActive}
      sidebarOpened={sidebarOpened}
      withScrollBar={withScrollBar}
      {...item}
    />
  );
});

Item.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    badge: PropTypes.number,
  }).isRequired,
  withScrollBar: PropTypes.bool.isRequired,
};

const SidebarItems = () => {
  const { currentCompany } = useContext(AccountContext);
  const {
    feature_configuration,
    billing_type,
    onboarding = {},
  } = currentCompany;
  const dispatch = useDispatch();
  const [alertCount, setAlertCount] = useState(0);
  const sidebarRef = useRef(null);
  const [withScrollbar, setWithScrollbar] = useState(false);
  const {
    lit_12082022_compliance: ffCompliance,
    'rd-plg-trial-dashboard': ffTrialDashboard,
    'vm_06252024_vuln-poc': ffVuln,
  } = useFlags([
    'lit_12082022_compliance',
    'rd-plg-trial-dashboard',
    'vm_06252024_vuln-poc',
  ]);
  const activityMonitor = useActivityMonitor();

  useEffect(() => {
    const alertStopper = startAlertMonitor(setAlertCount);
    activityMonitor.start();

    return () => {
      stopAlertMonitor(alertStopper);
      activityMonitor.stop();
    };
  }, []);

  // check if sidebar has scrollbar
  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (sidebar) {
      const hasVerticalScrollbar = sidebar.scrollHeight > sidebar.clientHeight;
      if (hasVerticalScrollbar) {
        setWithScrollbar(true);
      }
    }
  }, []);

  // const isTrial =
  //   billing_type === 'automated_trial' && Object.keys(onboarding).length > 0;
  const isTrial =
    billing_type === 'automated_trial' || billing_type === 'trial';

  const items = useMemo(() => {
    const tabs = [
      ...(isTrial && ffTrialDashboard
        ? [
            {
              link: links.trial,
              name: i18n.t('Trial'),
              icon: 'trophy',
            },
          ]
        : []),
      {
        link: links.devices,
        name: i18n.t('Devices'),
        icon: 'laptop-mobile',
        /* istanbul ignore next */
        onClick() {
          dispatch(setPageScroll(0));
        },
      },
      {
        link: links.blueprints,
        name: i18n.t('Blueprints'),
        icon: 'kandji-blueprint',
      },
      {
        link: links.library,
        name: i18n.t('Library'),
        icon: 'grid-2',
      },
      {
        link: links['integration-users'].withDevices,
        name: i18n.t('Users'),
        icon: 'user-group',
      },
      {
        link: links.activity,
        name: i18n.t('Activity'),
        icon: 'wave-pulse',
      },
      {
        link: links.alerts.active,
        name: i18n.t('Alerts'),
        icon: 'bell',
        badge: alertCount,
      },
      ...(feature_configuration?.edr?.enabled
        ? [
            {
              link: links.threat,
              name: i18n.t('Threats'),
              icon: 'shield-halved',
            },
          ]
        : []),
      ...(feature_configuration?.vulnerability_management?.enabled && ffVuln
        ? [
            {
              link: links.vulnerability,
              name: i18n.t('Vulnerability'),
              icon: 'shield-virus',
            },
          ]
        : []),
      ...(ffCompliance
        ? [
            {
              link: links.compliance,
              name: tabsNames.compliance,
              icon: 'clipboard-list-check',
            },
          ]
        : []),
    ];

    return tabs;
  }, [alertCount, feature_configuration, ffCompliance, dispatch]);

  return (
    <div className="sidebar-nav" ref={sidebarRef}>
      <div>
        <div>
          {items.map((item) => (
            <Item key={item.name} item={item} withScrollBar={withScrollbar} />
          ))}
        </div>
        <div>
          <FooterItems />
        </div>
      </div>
    </div>
  );
};

export default memo(SidebarItems);
