/* istanbul ignore file */
import {
  Badge,
  FilterButton,
  Flex,
  MultiSelect,
  Text,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

type SeverityMultiSelectProps = {
  severity: string[];
  setSeverity: (severity: string[]) => void;
};

const SeverityMultiSelect = (props: SeverityMultiSelectProps) => {
  const { severity = [], setSeverity } = props;

  const severityOptions = [
    { label: 'Critical [9.0 - 10.0]', value: 'Critical' },
    { label: 'High [7.0 - 8.9]', value: 'High' },
    { label: 'Medium [4.0 - 6.9]', value: 'Medium' },
    { label: 'Low [0.1 - 3.9]', value: 'Low' },
    { label: 'None [0.0]', value: 'None' },
  ];

  const visibleItems = severity.slice(0, 3);
  const hiddenItemCount = severity.length - visibleItems.length;

  return (
    <MultiSelect
      multi
      options={severityOptions}
      value={severity}
      onChange={(value) => setSeverity(value)}
      footer={{
        showClear: true,
        clearLabel: i18n.t('Clear'),
        handleClear: () => setSeverity([]),
      }}
      componentCss={{ menu: { zIndex: 3 } }}
    >
      <FilterButton
        filtersSelected={Boolean(severity.length)}
        showRemove={false}
        css={{ height: 28 }}
      >
        <Flex flow="row" alignItems="center">
          <Text>Severity</Text>
          {severity.length === severityOptions.length && (
            <Flex flow="row" gap="xs">
              <Text>: </Text>
              <Text css={{ fontWeight: '$medium' }}> {i18n.t('All')}</Text>
            </Flex>
          )}
          {severity.length !== severityOptions.length &&
            severity.length > 0 && (
              <Flex flow="row" gap="xs">
                <Text>:</Text>
                <Flex
                  alignItems="center"
                  css={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Text
                    css={{
                      fontWeight: '$medium',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {visibleItems
                      .map(
                        (item) =>
                          severityOptions
                            .find((option) => option.value === item)
                            ?.label.split(' ')[0],
                      )
                      .join(', ')}
                  </Text>
                </Flex>
                {hiddenItemCount > 0 && (
                  <Badge
                    compact
                    css={{
                      color: '$neutral0',
                      backgroundColor: '$blue50',
                    }}
                  >
                    +{hiddenItemCount}
                  </Badge>
                )}
              </Flex>
            )}
        </Flex>
      </FilterButton>
    </MultiSelect>
  );
};

export { SeverityMultiSelect };
