import { Label } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { links } from 'src/app/common/constants';
import { Setting } from 'src/features/library-items/template';
import type { IpaAppModel, IpaFile } from '../in-house-apps.types';
import { IPAUploader } from './install-uploader';

interface InstallDetailsProps {
  isDisabled: boolean;
  model: IpaAppModel;
  isSubmitted: boolean;
  updateIpaFile: (updateBody: Partial<IpaFile>) => any;
  onValidate: (cb: any) => (isValid: any) => any;
}

export function InstallDetails({
  isDisabled,
  model,
  updateIpaFile,
  onValidate,
  isSubmitted,
}: InstallDetailsProps) {
  const { installDetails } = model;
  const { ipaFile } = installDetails;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Install details')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'New versions will be immediately pushed out and continuously enforced.',
          )}{' '}
          <a
            href={links.ipaAppsLearnMore}
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t('Learn more')}
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <Label above>{i18n.t('.ipa file')}</Label>
          </Setting.Title>
          <Setting.Controls>
            <IPAUploader
              isDisabled={isDisabled}
              ipaFile={ipaFile}
              updateIpaFile={updateIpaFile}
              onValidate={onValidate}
              isSubmitted={isSubmitted}
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
}
