import { i18n } from 'i18n';
import { INT_TYPES } from './constants';

const integrationTitleMap = i18n.createMap({
  [INT_TYPES.slack]: () => i18n.t('Slack'),
  // [INT_TYPES.msTeams]: () => i18n.t('Microsoft Teams'),
  [INT_TYPES.gsuite]: () => i18n.t('Google Workspace'),
  // [INT_TYPES.scim]: () => i18n.t('SCIM'),
  // [INT_TYPES.office365]: () => i18n.t('Microsoft Entra ID'),
  [INT_TYPES.adcs]: () => i18n.t('Active Directory Certificate Services'),
  // [INT_TYPES.okta]: () => i18n.t('Okta Device Trust'),
});

export { integrationTitleMap };
